import { clsx } from 'clsx';

import { PepitaList } from 'src/libs/ui/pepita-list';

import type { AutocompleteBaseValue } from '../types';

import { AutocompleteListItem } from '../AutocompleteListItem';
import { LoaderItem } from '../LoaderItem';
import { NoResultsItem } from '../NoResultsItem';

type ListProps<V extends AutocompleteBaseValue> = {
  highlight: number | undefined;
  items: V[];
  onClick: (item: V) => void;
  searchString: string;
  loading: boolean;
  onItemHover?: (index: number) => () => void;
  customClass?: string;
  listContentCustomClass?: string;
};

export function AutocompleteList<V extends AutocompleteBaseValue>({
  highlight,
  items,
  loading,
  onClick,
  searchString,
  onItemHover,
  customClass,
  listContentCustomClass,
}: ListProps<V>) {
  const getChild = () => {
    if (loading) {
      return <LoaderItem />;
    }

    if (items.length) {
      return items.map((item, index) => (
        <AutocompleteListItem
          key={index}
          item={item}
          highlighted={highlight === index}
          searchString={searchString}
          onClick={onClick}
          onItemHover={onItemHover ? () => onItemHover(index) : undefined}
        />
      ));
    }

    return <NoResultsItem />;
  };

  return (
    <div
      className={clsx('nd-autocomplete__list is-open', customClass)}
      data-cy="autocomplete-list"
    >
      <div
        className={clsx('nd-autocomplete__listContent', listContentCustomClass)}
      >
        <PepitaList customClass="nd-stack">{getChild()}</PepitaList>
      </div>
    </div>
  );
}
