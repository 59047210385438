import { CATEGORIES } from 'src/constants/typologies';

import type { RangeConfig } from './formConfigTypes';
import { FormFields } from './formConfigTypes';

export const surfacesRangesConfiguration: RangeConfig['range'] = [
  {
    min: 20,
    max: 1000,
    step: {
      0: 20,
      200: 50,
      400: 100,
    },
  },
  {
    condition: {
      [FormFields.CATEGORY]: [CATEGORIES.PALAZZI_EDIFICI],
    },
    min: 200,
    max: 30000,
    step: {
      0: 200,
      200: 100,
      1000: 200,
      3000: 500,
      10000: 5000,
    },
  },
  {
    condition: {
      [FormFields.CATEGORY]: [CATEGORIES.MAGAZZINI_DEPOSITI],
    },
    min: 20,
    max: 20000,
    step: {
      0: 20,
      200: 50,
      500: 100,
      1000: 500,
      5000: 1000,
      10000: 5000,
    },
  },
  {
    condition: {
      [FormFields.CATEGORY]: [CATEGORIES.CAPANNONI],
    },
    min: 100,
    max: 70000,
    step: {
      0: 20,
      200: 50,
      500: 100,
      1000: 500,
      5000: 1000,
      10000: 5000,
      300000: 10000,
    },
  },
  {
    condition: {
      [FormFields.CATEGORY]: [
        CATEGORIES.UFFICI_COWORKING,
        CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
      ],
    },
    min: 20,
    max: 20000,
    step: {
      0: 20,
      200: 50,
      500: 100,
      1000: 500,
      5000: 1000,
      10000: 10000,
    },
  },
  {
    condition: {
      [FormFields.CATEGORY]: [CATEGORIES.TERRENI],
    },
    min: 500,
    max: 500000,
    step: {
      0: 500,
      5000: 5000,
      10000: 10000,
      50000: 50000,
      100000: 100000,
    },
  },
  {
    condition: {
      [FormFields.CATEGORY]: [CATEGORIES.NUOVE_COSTRUZIONI],
    },
    min: 20,
    max: 30000,
    step: {
      0: 20,
      200: 50,
      500: 100,
      1000: 500,
      3000: 1000,
      10000: 2000,
      20000: 5000,
    },
  },
];
