import { useEffect, useState } from 'react';
import { http } from '@pepita/http';
import { useQuery } from '@tanstack/react-query';
import type { PolygonNamingResponse } from 'src/repository/geography/api';

import type { GeographySearch } from 'src/libs/geography';

const formatPoints = (coordinates: number[] | string[]) =>
  [...coordinates].join(',');

export const usePolygonNamingApi = (geography: GeographySearch | null) => {
  const [coords, setCoords] = useState<string>();

  useEffect(() => {
    if (!geography || geography.searchType === 'place') {
      setCoords(undefined);
    } else {
      setCoords(
        geography.searchType === 'circle'
          ? formatPoints(geography.value.center)
          : geography.value.points
              .map((coords) => formatPoints(coords))
              .join(';')
      );
    }
  }, [geography]);

  const { data } = useQuery<PolygonNamingResponse>({
    queryKey: ['polygon-naming', coords],
    queryFn: () =>
      http
        .post('/api-next/geography/polygon-address/', {
          json: { coords },
        })
        .json(),
    staleTime: Infinity,
    enabled: Boolean(coords),
  });

  return data;
};
