import type {
  BaseEntity,
  EntityKey,
  GeographyValue,
} from 'src/types/geography';
import { ENTITY_TYPE } from 'src/types/geography';

export const isZone = ({ type }: BaseEntity) => type === ENTITY_TYPE.cityZone;
export const isMicrozone = ({ type }: BaseEntity) =>
  type === ENTITY_TYPE.microzone;
export const isMetro = ({ type }: BaseEntity) =>
  type === ENTITY_TYPE.metro || type === ENTITY_TYPE.metroLine;
export const isRegion = ({ type }: BaseEntity) => type === ENTITY_TYPE.region;
export const isCountry = ({ type }: BaseEntity) => type === ENTITY_TYPE.country;

export const isSameEntity = (e1: EntityKey, e2: EntityKey) =>
  e1.id === e2.id && e1.type === e2.type;

export const isChildOf = (parent: EntityKey, child: BaseEntity) =>
  Boolean(
    child.parents.find((p) => p.id === parent.id && p.type === parent.type)
  );

export const getEntityParentByType = (e: BaseEntity, type: ENTITY_TYPE) =>
  e.parents.find((parent) => parent.type === type);

export const getEntityChildByID = (
  entity: GeographyValue,
  id: string,
  type: ENTITY_TYPE
) => entity.children?.find((value) => isSameEntity(value, { id, type }));

export const areAllLeavesSelected = (
  parent: GeographyValue,
  currentSelection: GeographyValue[]
) =>
  parent.children?.every((child) =>
    currentSelection.find((value) => isSameEntity(value, child))
  );

/**
 * Check if two metro stations have the same name
 * @param m1
 * @param m2
 */
export const areSameMetro = (
  m1: GeographyValue,
  m2: GeographyValue
): boolean => {
  const m1Label = m1.originalLabel ? m1.originalLabel : m1.label;
  const m2Label = m2.originalLabel ? m2.originalLabel : m2.label;

  return m1Label === m2Label;
};

/**
 * Search an entity by type in the current selection and inside the parents
 * @param geography
 * @param type
 */
export const getGeographyEntityID = (
  geography: Optional<GeographyValue>,
  type: ENTITY_TYPE
): Optional<string> =>
  geography?.type === type
    ? geography.id
    : geography?.parents?.find((parent) => parent.type === type)?.id;
