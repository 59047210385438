import { isFeatureEnabled } from 'src/config/features-toggle';
import type { FieldConfig, FormConfig } from '../config/formConfigTypes';
import { FormFields } from '../config/formConfigTypes';

import { CONTRACT, NEW_COMMERCIAL_MAPPING } from 'src/constants/typologies';

import type { FormState } from '../types';

import { forceArrayValue } from 'src/utils/querystring';

import {
  fixArraysParams,
  fixInitialValue,
  getDefaultFieldValue,
  getDefaultHiddenFieldValue,
  getNewCommercialSearch,
} from './formStateHelpers';
import { getHiddenFieldsFromState } from './getHiddenFieldsFromState';

/** Update some properties of currentState with exceptions from
 * the initialState
 * @param initialState
 * @param currentState
 */
const mapExceptions = (initialState: FormState, currentState: FormState) => {
  if (initialState['tipoNegozioCommerciale']) {
    currentState[FormFields.TYPOLOGY] = getNewCommercialSearch(
      initialState['tipoNegozioCommerciale']
    );
  }

  if (initialState['tipologiaCommerciale']) {
    currentState[FormFields.TYPOLOGY] = forceArrayValue(
      initialState['tipologiaCommerciale']
    ).map((typology) => NEW_COMMERCIAL_MAPPING[typology]);
  }

  if (initialState['categoriaStanza']) {
    currentState[FormFields.TYPOLOGY] = initialState['categoriaStanza'];
  }

  if (isFeatureEnabled('BEDROOM_SEARCH_ENABLED')) {
    currentState[FormFields.MAX_NUMBER_OF_ROOMS] = null;
    currentState[FormFields.MIN_NUMBER_OF_ROOMS] = null;
  } else {
    currentState[FormFields.MAX_BEDROOMS] = null;
    currentState[FormFields.MIN_BEDROOMS] = null;
  }

  // Mapping old field name
  if (initialState['fkGiardino']) {
    const values = forceArrayValue(initialState['fkGiardino']);

    currentState[FormFields.GARDEN] = values.map((value) => String(value));
  }

  // Mapping auctions
  if (initialState['inAsta'] && initialState['inAsta'] === '1') {
    currentState[FormFields.CONTRACT] = CONTRACT.ASTE;
  }

  // Mapping aReddito
  if (initialState[FormFields.INVESTMENT] === '1') {
    currentState[FormFields.PROPERTY_TYPE] = 'aReddito';
  }

  // Mapping idTipologia
  if (initialState[FormFields.TYPOLOGY]?.length === 0) {
    currentState[FormFields.TYPOLOGY] = undefined;
  }
};

const getStateWithKey = (
  fieldKey: FormFields,
  initialState: FormState,
  fieldConfig?: FieldConfig
) => {
  if (fieldConfig?.type === 'hidden') {
    return fieldConfig.value;
  }

  if (initialState[fieldKey] || initialState[fieldKey] === 0) {
    return fixInitialValue(fieldKey, initialState[fieldKey]);
  }

  return getDefaultFieldValue(fieldConfig);
};

export const initFormStateData = (
  initialState: FormState,
  formConfig: FormConfig
) => {
  const state: FormState = {};

  Object.values(FormFields).forEach((fieldKey) => {
    const fieldConfig = formConfig.find((field) => field.field === fieldKey);

    state[fieldKey] = getStateWithKey(fieldKey, initialState, fieldConfig);
  });

  mapExceptions(initialState, state);
  fixArraysParams(state, formConfig);

  const hiddenFields = getHiddenFieldsFromState(state, formConfig);

  // Cleaning hidden fields. We need to do this action at the end of state
  // initialization as some fields depends on previous transformations
  // e.g. fkLicenza depends on idTipologia which is filled with data
  // from tipoNegozioCommerciale
  hiddenFields.forEach((fieldConfig) => {
    state[fieldConfig.field] = getDefaultHiddenFieldValue(fieldConfig);
  });

  return state;
};
