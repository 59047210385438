import type { FieldConfig, FormConfig } from '../config/formConfigTypes';
import { FormFields } from '../config/formConfigTypes';

import { CATEGORIES, SHOPS } from 'src/constants/typologies';

import type { FormState } from '../types';

import { forceArrayValue } from 'src/utils/querystring';

import { getHiddenFieldsFromState } from './getHiddenFieldsFromState';

// fix double render on page load with some input parameters
// see: https://gitlab.pepita.io/indomio/indomita/website-next/issues/351
export const fixInitialValue = (fieldKey, value) => {
  if (
    fieldKey === FormFields.MIN_PRICE ||
    fieldKey === FormFields.MAX_PRICE ||
    fieldKey === FormFields.MIN_SURFACE ||
    fieldKey === FormFields.MAX_SURFACE ||
    fieldKey === FormFields.MIN_BEDROOMS ||
    fieldKey === FormFields.MAX_BEDROOMS ||
    fieldKey === FormFields.MIN_NUMBER_OF_ROOMS ||
    fieldKey === FormFields.MAX_NUMBER_OF_ROOMS
  ) {
    return parseInt(value, 10);
  }

  return value;
};

export const resetForm = (oldState: FormState, formConfig: FormConfig) => {
  const initialFieldValues = {};

  Object.keys(oldState)
    .filter(
      (field) =>
        field !== FormFields.CONTRACT &&
        field !== FormFields.CATEGORY &&
        field !== FormFields.TYPOLOGY
    )
    .forEach((field) => {
      if (formConfig.find((conf) => conf.field === field)?.type !== 'hidden') {
        if (Array.isArray(oldState[field])) {
          initialFieldValues[field] = [];
        } else {
          initialFieldValues[field] = null;
        }
      } else {
        // Maintain hidden fields
        initialFieldValues[field] = oldState[field];
      }
    });

  return initialFieldValues;
};

/**
 * Similar to reset form but it maintains unchanged fields which have changed
 * from last iteration
 * @param state
 * @param oldState
 * @param formConfig
 */
export const resetUnchangedFields = (
  state: FormState,
  oldState: FormState,
  formConfig: FormConfig
) => {
  const initialFieldValues = {};

  Object.keys(state)
    .filter(
      (field) =>
        field !== FormFields.CONTRACT &&
        field !== FormFields.CATEGORY &&
        field !== FormFields.TYPOLOGY
    )
    .forEach((field) => {
      if (formConfig.find((conf) => conf.field === field)?.type !== 'hidden') {
        if (Array.isArray(state[field])) {
          initialFieldValues[field] = state[field].every(
            (value, index) => value === oldState?.[field]?.[index]
          )
            ? []
            : [...state[field]];
        } else {
          initialFieldValues[field] =
            state[field] === oldState?.[field] ? null : state[field];
        }
      } else {
        // Maintain hidden fields
        initialFieldValues[field] = state[field];
      }
    });

  return initialFieldValues;
};

export const getDefaultFieldValue = (fieldConfig: FieldConfig) => {
  switch (fieldConfig?.type) {
    case 'category':
      return CATEGORIES.RESIDENZIALE;
    case 'multiselect':
      return [];
    case 'contract':
      return Object.keys(fieldConfig.options)[0];
    case 'hidden':
      return fieldConfig.value;
    case 'select':
    default:
      return null;
  }
};

export const getDefaultHiddenFieldValue = (fieldConfig: FieldConfig) => {
  switch (fieldConfig?.type) {
    case 'category':
      return CATEGORIES.RESIDENZIALE;
    case 'multiselect':
      return [];
    case 'contract':
      return Object.keys(fieldConfig.options)[0];
    case 'hidden':
    case 'select':
    default:
      return null;
  }
};

/**
 * Sometimes the initial state is not correctly defined,
 * for example params that should be arrays are primitive
 * values
 * @param currentState
 * @param formConfig
 */
export const fixArraysParams = (
  currentState: FormState,
  formConfig: FormConfig
) => {
  Object.keys(currentState).forEach((currentField) => {
    const fieldConfig = formConfig.find(
      (field) => field.field === currentField
    );

    // Fixing multiselect values
    if (fieldConfig && fieldConfig.type === 'multiselect') {
      currentState[currentField] = forceArrayValue(currentState[currentField]);
    }
  });

  // Fixing typologies
  if (currentState[FormFields.TYPOLOGY]) {
    currentState[FormFields.TYPOLOGY] = forceArrayValue(
      currentState[FormFields.TYPOLOGY]
    );
  }
};

// With https://gitlab.pepita.io/immobiliare/site-v6/merge_requests/3470
// All commercial typologies had been mapped into only three values, so we
// have to map old values from urls into new ones
export const FORM_OPTIONS_MAPPING = {
  [SHOPS.MURA]: [SHOPS.MURA, SHOPS.MURLAB],
  [SHOPS.LABORATORIO]: [SHOPS.LABORATORIO],
  [SHOPS.ATTIVITA]: [
    SHOPS.ATTIVITA,
    SHOPS.MURATT,
    SHOPS.ATTLAB,
    SHOPS.MURATTLAB,
  ],
};

export const getNewCommercialSearch = (options: string[]) => {
  return [
    ...new Set(
      options.map((oldOpt) =>
        Object.keys(FORM_OPTIONS_MAPPING).find((newOpt) =>
          FORM_OPTIONS_MAPPING[newOpt].find(
            (mappedOption) => mappedOption === oldOpt
          )
        )
      )
    ),
  ];
};

export const getFormDataWithoutHidden = (
  formState: FormState,
  formConfig: FormConfig
): FormState => {
  const hiddenFields = getHiddenFieldsFromState(formState, formConfig);

  const paramsToAdd = Object.keys(formState).filter(
    (fieldName: FormFields) =>
      !hiddenFields.find((hiddenField) => hiddenField.field === fieldName)
  );

  const formStateWithoutHidden: FormState = {};

  paramsToAdd.forEach(
    (param) => (formStateWithoutHidden[param] = formState[param])
  );

  return formStateWithoutHidden;
};
