import { useTranslations } from '@pepita-react/i18n';
import { useSetAtom } from 'jotai';

import { comeFromSearchOptionsAtom } from '../atoms/comeFromSearchOptionsAtom';

import { useComponentControls } from '../hooks/useComponentControls';

import { OptionsItem } from './OptionsItem';

interface SelectOnMapProps {
  hasInitialValue: boolean;
}

export function SelectOnMap({ hasInitialValue }: SelectOnMapProps) {
  const { trans } = useTranslations();
  const { openSearchModal } = useComponentControls();
  const setComeFromSearchOptions = useSetAtom(comeFromSearchOptionsAtom);

  return (
    <OptionsItem
      icon={{ name: 'option-zones' }}
      label={trans('search_option_select_on_map_label')}
      description={trans('search_option_select_on_map_description')}
      onSelect={() => {
        setComeFromSearchOptions(true);
        openSearchModal('PLACE', hasInitialValue);
      }}
    />
  );
}
