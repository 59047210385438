import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { geographyComponentStateAtom, geographySearchAtom } from '../atoms';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useComponentControls } from '../hooks/useComponentControls';
import { useGetPlaceAutocompletePlaceholder } from '../hooks/useGetPlaceAutocompletePlaceholder';

import {
  isAreaSearchGeography,
  isDistanceSearchGeography,
  isPlaceSearchGeographyWithChildren,
} from 'src/libs/geography';

import {
  useGeographySearchContext,
  useIsSearchFeaturesEnabled,
} from '../context';
import { GeographyTag } from '../GeographyTag';
import { PlaceAutocomplete } from '../PlaceAutocomplete';
import { PlaceCheckList } from '../PlaceCheckList';
import { SearchOptions } from '../SearchOptions';

import css from './styles.module.scss';

interface DesktopGeographySearchProps {
  error: boolean;
  hasResultCountButton?: boolean;
  geographyTagCustomClass?: string;
}

export function DesktopGeographySearch({
  error = false,
  hasResultCountButton,
  geographyTagCustomClass,
}: DesktopGeographySearchProps) {
  const { closeSearchOptions, openSearchOptions, openSearchModal } =
    useComponentControls();
  const autocompleteInputRef = useRef<HTMLInputElement>(null);
  const placeAutocompleteRef = useRef<HTMLInputElement>(null);
  const searchOptionsRef = useRef<HTMLDivElement>(null);
  const { searchOptionsOpen } = useAtomValue(geographyComponentStateAtom);
  const geographySearch = useAtomicStateAtomValue(geographySearchAtom);
  const { hasGenericTag } = useGeographySearchContext();
  const isTagVisible = Boolean(geographySearch) || hasGenericTag;
  const [hasTag, setHasTag] = useState(isTagVisible);
  const [isPlaceCheckListOpen, setIsPlaceCheckListOpen] = useState(false);
  const getPlaceAutocompletePlaceholder =
    useGetPlaceAutocompletePlaceholder(isPlaceCheckListOpen);

  const isSearchFeaturesEnabled = useIsSearchFeaturesEnabled();
  const isAreaSearchEnabled = isSearchFeaturesEnabled('areaSearch');
  const isDistanceSearchEnabled = isSearchFeaturesEnabled('distanceSearch');

  const isGeographyTagInteractive =
    geographySearch &&
    (isPlaceSearchGeographyWithChildren(geographySearch) ||
      isDistanceSearchGeography(geographySearch) ||
      isAreaSearchGeography(geographySearch));

  // To correctly set hasTag after the initial load because geographyData is
  // populated from local storage. This ensures the state stays in sync
  // with the stored value once it's available.
  useEffect(() => {
    setHasTag(isTagVisible);
  }, [isTagVisible]);

  const handleGeographyTagClick = useCallback(() => {
    if (!geographySearch) return;

    closeSearchOptions();

    if (isPlaceSearchGeographyWithChildren(geographySearch)) {
      setIsPlaceCheckListOpen((prevState) => !prevState);

      return;
    }

    if (isDistanceSearchGeography(geographySearch)) {
      openSearchModal('DISTANCE', hasTag);

      return;
    }

    if (isAreaSearchGeography(geographySearch)) {
      openSearchModal('AREA', hasTag);

      return;
    }

    return;
  }, [geographySearch, closeSearchOptions, openSearchModal, hasTag]);

  // This useCallback is needed since this function is a dependency of a
  // useEffect in usePlaceAutocomplete hook. Please, do not remove it.
  const handleSetGeography = useCallback((isGeographyWithChildren: boolean) => {
    if (isGeographyWithChildren) {
      setIsPlaceCheckListOpen(true);
    }

    setHasTag(true);
  }, []);

  const handleRemove = () => {
    autocompleteInputRef.current?.focus();
    if (isPlaceCheckListOpen) {
      setIsPlaceCheckListOpen(false);
    }

    openSearchOptions();
    setHasTag(false);
  };

  const handleAutocompleteClick = useCallback(() => {
    if (isPlaceCheckListOpen) {
      return;
    }

    if (!searchOptionsOpen) {
      openSearchOptions();
    }
  }, [isPlaceCheckListOpen, openSearchOptions, searchOptionsOpen]);

  const handleAutocompleteInput = useCallback(() => {
    closeSearchOptions();
    setIsPlaceCheckListOpen(false);
  }, [closeSearchOptions]);

  const onClickOutSide = useCallback(
    (event: Event) => {
      if (event.target === autocompleteInputRef.current) {
        return;
      }

      autocompleteInputRef.current?.blur();

      if (searchOptionsOpen) {
        closeSearchOptions();
      }

      if (isPlaceCheckListOpen) {
        setIsPlaceCheckListOpen(false);
      }

      if (isTagVisible && !hasTag) {
        setHasTag(true);
      }
    },
    [
      closeSearchOptions,
      hasTag,
      isPlaceCheckListOpen,
      isTagVisible,
      searchOptionsOpen,
    ]
  );

  useClickOutside(
    searchOptionsRef,
    (event) => onClickOutSide(event),
    searchOptionsOpen
  );

  // Close the placeChecklist when clicking outside the geography component.
  useClickOutside(
    placeAutocompleteRef,
    (event) => onClickOutSide(event),
    isPlaceCheckListOpen
  );

  return (
    <>
      <PlaceAutocomplete
        error={error}
        onSetGeography={handleSetGeography}
        inputRef={autocompleteInputRef}
        ref={placeAutocompleteRef}
      >
        <PlaceAutocomplete.InputContainer>
          {hasTag ? (
            <GeographyTag
              onClick={
                isGeographyTagInteractive ? handleGeographyTagClick : undefined
              }
              onRemove={handleRemove}
              customClass={clsx(
                css['in-desktopGeographySearch__maxWidthTag'],
                geographyTagCustomClass
              )}
            />
          ) : (
            <PlaceAutocomplete.Icon />
          )}

          <PlaceAutocomplete.Input
            placeholder={(isFocused) =>
              getPlaceAutocompletePlaceholder(isFocused, hasTag)
            }
            onClick={handleAutocompleteClick}
            onInput={handleAutocompleteInput}
          />
        </PlaceAutocomplete.InputContainer>

        <PlaceAutocomplete.SuggestionList />

        {isPlaceCheckListOpen && (
          <PlaceCheckList>
            {hasResultCountButton && (
              <PlaceCheckList.ResultCountButton
                onResultClick={() => {
                  setIsPlaceCheckListOpen(false);
                }}
              />
            )}
          </PlaceCheckList>
        )}
      </PlaceAutocomplete>

      {searchOptionsOpen && (
        <SearchOptions ref={searchOptionsRef}>
          <SearchOptions.CurrentSearch isVisible={hasTag} />
          <SearchOptions.SelectOnMap hasInitialValue={hasTag} />
          {isAreaSearchEnabled && (
            <SearchOptions.DrawAreaOnMap hasInitialValue={hasTag} />
          )}
          {isDistanceSearchEnabled && (
            <SearchOptions.DistanceFromAPoint hasInitialValue={hasTag} />
          )}
        </SearchOptions>
      )}
    </>
  );
}
