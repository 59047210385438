import type { PropsWithChildren, RefObject } from 'react';
import { forwardRef, useRef } from 'react';
import clsx from 'clsx';

import { usePlaceAutocomplete } from './usePlaceAutocomplete';

import type { PlaceSearchGeography } from 'src/libs/geography';

import { PlaceAutocompleteContext } from './context';
import { Icon } from './Icon';
import { Input } from './Input';
import { InputContainer } from './InputContainer';
import { SuggestionList } from './SuggestionList';

import css from './styles.module.scss';

interface PlaceAutocompleteProps extends PropsWithChildren {
  customClass?: string;
  inputRef?: RefObject<HTMLInputElement>;
  hasFixedHeight?: boolean;
  error?: boolean;
  onSetGeography?: (
    isGeographyWithChildren: boolean,
    placeSearchGeography: PlaceSearchGeography
  ) => void;
}

export const PlaceAutocompleteRoot = forwardRef<
  HTMLDivElement,
  PlaceAutocompleteProps
>(function PlaceAutocompleteRoot(props, ref) {
  const defaultInputRef = useRef<HTMLInputElement>(null);

  const {
    children,
    customClass,
    inputRef = defaultInputRef,
    hasFixedHeight,
    error,
    onSetGeography,
  } = props;

  const autocomplete = usePlaceAutocomplete({
    inputRef,
    onSetGeography,
  });

  return (
    <PlaceAutocompleteContext.Provider
      value={{ autocomplete, error, hasFixedHeight, inputRef }}
    >
      <div
        className={clsx(
          'nd-autocomplete',
          css['in-placeAutocomplete__autocomplete'],
          customClass
        )}
        ref={ref}
      >
        {children}
      </div>
    </PlaceAutocompleteContext.Provider>
  );
});

export const PlaceAutocomplete = Object.assign(PlaceAutocompleteRoot, {
  InputContainer,
  Input,
  SuggestionList,
  Icon,
});
