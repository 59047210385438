import { useEffect, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { useGeographySearchAtom } from '../hooks/useGeographySearchAtom';
import { usePolygonNamingApi } from '../hooks/usePolygonNamingApi';

import {
  isDistanceSearchIsolineGeography,
  isPlaceSearchGeography,
} from 'src/libs/geography';
import { PepitaTag } from 'src/libs/ui/pepita-tag';

import { getDrawingTagLabel } from '../utils/addresses';
import { getGeographyTagLabel } from '../utils/labels';

import {
  useGeographySearchContext,
  useIsSearchFeaturesEnabled,
} from '../context';

import css from './styles.module.scss';

interface GeographyTagProps {
  hasFixedHeight?: boolean;
  customClass?: string;
  onClick?: () => void;
  onRemove?: () => void;
}

export function GeographyTag({
  hasFixedHeight = false,
  customClass,
  onClick,
  onRemove,
}: GeographyTagProps) {
  const { trans } = useTranslations();
  const [label, setLabel] = useState<Nullable<string>>();
  const [geographySearch] = useGeographySearchAtom();

  const { hasGenericTag } = useGeographySearchContext();
  const isSearchFeaturesEnabled = useIsSearchFeaturesEnabled();

  /** Effect for setting drawings label **/
  const polygonName = usePolygonNamingApi(geographySearch);

  const isGenericAreaSearch = !geographySearch && hasGenericTag;

  useEffect(() => {
    if (isGenericAreaSearch) {
      setLabel(trans('geo_map_area'));

      return;
    }

    if (!geographySearch) {
      setLabel(null);

      return;
    }

    if (isPlaceSearchGeography(geographySearch)) {
      setLabel(
        getGeographyTagLabel(
          geographySearch.value,
          isSearchFeaturesEnabled('labelsForCountry'),
          trans
        )
      );

      return;
    }

    if (isDistanceSearchIsolineGeography(geographySearch)) {
      setLabel(geographySearch.value.label);

      return;
    }

    // Distance search circle and Area search
    setLabel(getDrawingTagLabel(geographySearch, trans, polygonName));
  }, [
    geographySearch,
    isSearchFeaturesEnabled,
    polygonName,
    trans,
    isGenericAreaSearch,
  ]);

  const notInteractive = isGenericAreaSearch || !onClick;
  const notRemovable = isGenericAreaSearch || !onRemove;

  return label ? (
    <div
      className={clsx(
        css['in-geographyTags'],
        hasFixedHeight && css['has-fixedHeight'],
        customClass
      )}
      title={label}
      data-cy="geography-tag"
    >
      <PepitaTag
        onClick={notInteractive ? undefined : onClick}
        onRemove={notRemovable ? undefined : onRemove}
        customClass={clsx(
          css['in-geographyTags__tag'],
          notInteractive && css['is-notInteractive'],
          notRemovable && css['is-notRemovable']
        )}
      >
        {label}
      </PepitaTag>
    </div>
  ) : null;
}
