import type { PropsWithChildren } from 'react';
import { Children, forwardRef, isValidElement } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { CurrentSearch } from './CurrentSearch';
import { DistanceFromAPoint } from './DistanceFromAPoint';
import { DrawAreaOnMap } from './DrawAreaOnMap';
import { SearchByName } from './SearchByName';
import { SelectOnMap } from './SelectOnMap';

import css from './styles.module.scss';

const SearchOptionsRoot = forwardRef<HTMLDivElement, PropsWithChildren>(
  function SearchOptionsRoot({ children }, ref) {
    const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

    // TODO: SearchOptions Keyboard Navigation

    const [firstChild, ...restChildren] = Children.toArray(children);

    if (!isValidElement(firstChild) || firstChild.type !== CurrentSearch) {
      throw new Error(
        'The first child must be the SearchOptions.CurrentSearch component'
      );
    }

    return (
      <div
        data-cy="search-options-list-container"
        className={clsx(
          !maxScreenWidthSm && 'nd-autocomplete__list',
          'is-open',
          css['in-searchOptions']
        )}
        ref={ref}
      >
        {firstChild}

        <ul className="nd-stack" data-cy="search-options-list">
          {restChildren}
        </ul>
      </div>
    );
  }
);

export const SearchOptions = Object.assign(SearchOptionsRoot, {
  CurrentSearch,
  SearchByName,
  SelectOnMap,
  DrawAreaOnMap,
  DistanceFromAPoint,
});
