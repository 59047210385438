import mainFeaturesSprite from '@pepita-fe/sprite-listing-main-features/sprite.svg';
import filterSprite from '@pepita-fe/sprite-listing-search/sprite.svg';
import secondaryFeaturesSprite from '@pepita-fe/sprite-listing-secondary-features/sprite.svg';

import { isFeatureEnabled } from 'src/config/features-toggle';

import {
  CATEGORIES,
  CONTRACT,
  ROOM_CATEGORIES,
  ROOM_TYPOLOGIES,
  SHOPS,
  TYPOLOGIES,
} from 'src/constants/typologies';

import type { GeographySearch } from 'src/libs/geography';

import type { Language } from 'src/types/translations';

import { adminCentres } from './adminCentres';
import type { FormConfig } from './formConfigTypes';
import { FormFields } from './formConfigTypes';
import { priceRangesConfiguration } from './priceRangesConfiguration';
import { surfacesRangesConfiguration } from './surfacesRangesConfiguration';

const isEnabledSellerLead = isFeatureEnabled('SELLER_LEAD');

const contracts = {
  [CONTRACT.VENDITA]: isEnabledSellerLead ? 'lbl_buy' : 'lbl_for_sale', // i18n-extract-keys ["lbl_for_sale", "lbl_buy"]
  [CONTRACT.AFFITTO]: isEnabledSellerLead ? 'lbl_rent' : 'lbl_for_rent', // i18n-extract-keys ["lbl_for_rent", "lbl_rent"]
};

if (isFeatureEnabled('AUCTIONS_ENABLED')) {
  contracts[CONTRACT.ASTE] = isEnabledSellerLead
    ? 'lbl_for_auction'
    : 'lbl_auction'; // i18n-extract-keys ["lbl_auction", "lbl_for_auction"]
}

const formConfig: FormConfig = [
  {
    field: FormFields.CONTRACT,
    type: 'contract',
    label: 'lbl_contract', // i18n-extract-keys ["lbl_contract"]
    topFilter: true, // Show outside of hidden filters
    options: contracts,
    icon: { name: 'certificate', spriteUrl: filterSprite },
  },
  {
    field: FormFields.CATEGORY,
    icon: { name: 'palaces', spriteUrl: filterSprite },
    type: 'category',
    label: 'lbl_typology', // i18n-extract-keys ["lbl_typology"]
    topFilter: true,
  },
  {
    field: FormFields.PRICE,
    icon: { name: 'money', spriteUrl: filterSprite },
    type: 'range',
    label: 'lbl_price', // i18n-extract-keys ["lbl_price"]
    fromName: FormFields.MIN_PRICE,
    toName: FormFields.MAX_PRICE,
    translationKeys: {
      label: 'price_range_label', // i18n-extract-keys ["price_range_label"]
      fromValue: {
        label: 'price_range_min_only', // i18n-extract-keys ["price_range_min_only"],
      },
      toValue: {
        label: 'price_range_max_only', // i18n-extract-keys ["price_range_max_only"]
      },
      fromToValue: 'price_range_min_max_dash', // i18n-extract-keys ["price_range_min_max_dash"]
      value: 'price_range_value', // i18n-extract-keys ["price_range_value"]
      from: 'price_range_label_from', // i18n-extract-keys ["price_range_label_from"]
      to: 'price_range_label_to', // i18n-extract-keys ["price_range_label_to"]
      title: 'price_range_label', // i18n-extract-keys ["price_range_label"]
    },
    topFilter: true,
    shortLabelValues: true,
    conditionType: 'and',
    range: priceRangesConfiguration,
  },
  {
    field: FormFields.ROOM_TYPOLOGY,
    type: 'multiselect',
    label: 'lbl_room_typology', // i18n-extract-keys ["lbl_room_typology"]
    options: [
      {
        id: ROOM_TYPOLOGIES.STANZA_SINGOLA,
        value: 'category_field_single_room', // i18n-extract-keys ["category_field_single_room"]
      },
      {
        id: ROOM_TYPOLOGIES.STANZA_DOPPIA,
        value: 'category_field_double_room', // i18n-extract-keys ["category_field_double_room"]
      },
      {
        id: ROOM_TYPOLOGIES.STANZA_TRIPLA,
        value: 'category_field_triple_room', // i18n-extract-keys ["category_field_triple_room"]
      },
    ],
    topFilter: false,
    show: {
      type: 'and',
      fields: {
        [FormFields.CATEGORY]: [CATEGORIES.STANZE],
        [FormFields.TYPOLOGY]: [ROOM_CATEGORIES.COMPLETA],
      },
    },
  },
  {
    field: FormFields.ROOM_BED_TYPOLOGY,
    type: 'multiselect',
    label: 'lbl_room_bed_typology', // i18n-extract-keys ["lbl_room_bed_typology"]
    options: [
      { id: '2', value: 'lbl_bed_in_double_room' }, // i18n-extract-keys ["lbl_bed_in_double_room"]
      { id: '3', value: 'lbl_bed_in_triple_room' }, // i18n-extract-keys ["lbl_bed_in_triple_room"]
      { id: '4', value: 'lbl_bed_in_multiple_room' }, // i18n-extract-keys ["lbl_bed_in_multiple_room"]
    ],
    topFilter: false,
    show: {
      type: 'and',
      fields: {
        [FormFields.CATEGORY]: [CATEGORIES.STANZE],
        [FormFields.TYPOLOGY]: [ROOM_CATEGORIES.POSTO_LETTO],
      },
    },
  },
  {
    field: FormFields.SURFACE,
    icon: { name: 'size', spriteUrl: mainFeaturesSprite },
    type: 'range',
    label: 'lbl_measurement', // i18n-extract-keys ["lbl_measurement"]
    fromName: FormFields.MIN_SURFACE,
    toName: FormFields.MAX_SURFACE,
    translationKeys: {
      label: 'surface_range_label', // i18n-extract-keys ["surface_range_label"]
      fromValue: {
        label: 'surface_range_min_only', // i18n-extract-keys ["surface_range_min_only"]
      },
      toValue: {
        label: 'surface_range_max_only', // i18n-extract-keys ["surface_range_max_only"]
      },
      fromToValue: 'surface_range_min_max_dash', // i18n-extract-keys ["surface_range_min_max_dash"]
      value: 'surface_range_value', // i18n-extract-keys ["surface_range_value"]
      from: 'surface_range_label_from', // i18n-extract-keys ["surface_range_label_from"]
      to: 'surface_range_label_to', // i18n-extract-keys ["surface_range_label_to"]
      title: 'surface_range_label', // i18n-extract-keys ["surface_range_label"]
    },
    topFilter: true,
    conditionType: 'or',
    range: surfacesRangesConfiguration,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.STANZE,
          CATEGORIES.GARAGE_POSTI_AUTO,
        ],
      },
    },
  },
  {
    field: FormFields.NUMBER_OF_ROOMS,
    icon: { name: 'planimetry', spriteUrl: mainFeaturesSprite },
    type: 'range',
    label: 'lbl_rooms', // i18n-extract-keys ["lbl_rooms"]
    fromName: FormFields.MIN_NUMBER_OF_ROOMS,
    toName: FormFields.MAX_NUMBER_OF_ROOMS,
    translationKeys: {
      label: 'room_range_label', // i18n-extract-keys ["room_range_label"]
      fromValue: {
        label: 'listing_meta_min_room', // i18n-extract-keys ["listing_meta_min_room"]
        withCount: true,
      },
      toValue: {
        label: 'listing_meta_max_room', // i18n-extract-keys ["listing_meta_max_room"]
        withCount: true,
      },
      sameValue: {
        label: 'nr_room', // i18n-extract-keys ["nr_room"]
        withCount: true,
      },
      fromToValue: 'room_range_min_max_with_text', // i18n-extract-keys ["room_range_min_max_with_text"]
      value: 'room_range_value', // i18n-extract-keys ["room_range_value"]
      from: 'room_range_label_from', // i18n-extract-keys ["room_range_label_from"]
      to: 'room_range_label_to', // i18n-extract-keys ["room_range_label_to"]
      title: 'room_range_label', // i18n-extract-keys ["room_range_label"]
    },
    topFilter: true,
    conditionType: 'or',
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.COMMERCIALE,
          CATEGORIES.STANZE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [
          TYPOLOGIES.BOX_GARAGE,
          TYPOLOGIES.BOX_AUTO,
          TYPOLOGIES.TERRENO,
          TYPOLOGIES.PALAZZO_STABILE,
          TYPOLOGIES.MAGAZZINO,
        ],
      },
    },
    range: [
      {
        min: 1,
        max: 5,
        step: {
          0: 1,
        },
      },
    ],
  },
  {
    field: FormFields.BATHROOMS,
    icon: { name: 'bath', spriteUrl: mainFeaturesSprite },
    type: 'select',
    label: 'lbl_bathrooms', // i18n-extract-keys ["lbl_bathrooms"]
    options: [
      { id: '1', value: '1', countLabel: 'search_formatter_nr_bathroom' }, // i18n-extract-keys ["search_formatter_nr_bathroom"]
      { id: '2', value: '2', countLabel: 'search_formatter_nr_bathroom' }, // i18n-extract-keys ["search_formatter_nr_bathroom"]
      { id: '3', value: '3', countLabel: 'search_formatter_nr_bathroom' }, // i18n-extract-keys ["search_formatter_nr_bathroom"]
      { id: '4', value: '>3', label: 'more_than_three_bathrooms' }, // i18n-extract-keys ["more_than_three_bathrooms"]
    ],
    topFilter: true,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.COMMERCIALE,
          CATEGORIES.STANZE,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [
          TYPOLOGIES.TERRENO,
          TYPOLOGIES.BOX_GARAGE,
          TYPOLOGIES.PALAZZO_STABILE,
        ],
      },
    },
  },
  {
    field: FormFields.FLOOR_ZONE,
    icon: { name: 'stairs', spriteUrl: mainFeaturesSprite },
    type: 'multiselect',
    label: 'lbl_floor', // i18n-extract-keys ["lbl_floor"]
    options: [
      { id: '10', value: 'ground_floor' }, // i18n-extract-keys ["ground_floor"]
      { id: '20', value: 'intermediate_floors' }, // i18n-extract-keys ["intermediate_floors"]
      { id: '30', value: 'top_floor' }, // i18n-extract-keys ["top_floor"]
    ],
    topFilter: true,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [
          TYPOLOGIES.TERRENO,
          TYPOLOGIES.BOX_GARAGE,
          TYPOLOGIES.PALAZZO_STABILE,
        ],
      },
    },
  },
  {
    field: FormFields.TENANT_SEX,
    type: 'select',
    label: 'lbl_flatmates', // i18n-extract-keys ["lbl_flatmates"]
    options: [
      { id: 'M', value: 'only_male' }, // i18n-extract-keys ["only_male"]
      { id: 'F', value: 'only_female' }, // i18n-extract-keys ["only_female"]
      { id: 'E', value: 'male_and_female' }, // i18n-extract-keys ["male_and_female"]
    ],
    topFilter: false,
    show: {
      fields: {
        [FormFields.CATEGORY]: [CATEGORIES.STANZE],
      },
    },
  },
  {
    field: FormFields.TENANT_JOB,
    type: 'select',
    label: 'lbl_employment', // i18n-extract-keys ["lbl_employment"]
    options: [
      { id: 'S', value: 'only_students' }, // i18n-extract-keys ["only_students"]
      { id: 'L', value: 'only_workers' }, // i18n-extract-keys ["only_workers"]
      { id: 'E', value: 'students_and_workers' }, // i18n-extract-keys ["students_and_workers"]
    ],
    topFilter: false,
    show: {
      fields: {
        [FormFields.CATEGORY]: [CATEGORIES.STANZE],
      },
    },
  },
  {
    field: FormFields.GARDEN,
    icon: { name: 'tree', spriteUrl: secondaryFeaturesSprite },
    type: 'multiselect',
    label: 'lbl_garden', // i18n-extract-keys ["lbl_garden"]
    options: [
      { id: '10', value: 'private_garden' }, // i18n-extract-keys ["private_garden"]
      { id: '20', value: 'shared_garden' }, // i18n-extract-keys ["shared_garden"]
    ],
    topFilter: false,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.COMMERCIALE,
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.STANZE,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [
          TYPOLOGIES.TERRENO,
          TYPOLOGIES.BOX_GARAGE,
          TYPOLOGIES.PALAZZO_STABILE,
        ],
      },
    },
  },
  {
    field: FormFields.OTHER_FEATURES,
    icon: { name: 'beach-umbrella', spriteUrl: secondaryFeaturesSprite },
    type: 'group',
    label: 'other_features', // i18n-extract-keys ["other_features"]
    topFilter: true,
  },
  {
    field: FormFields.BUILDING_USE,
    type: 'multiselect',
    label: 'building_use', // i18n-extract-keys ["building_use"]
    options: [
      { id: '1', value: 'db_building_use.id_1' }, // i18n-extract-keys ["db_building_use.id_1"]
      { id: '10', value: 'db_building_use.id_10' }, // i18n-extract-keys ["db_building_use.id_10"]
      { id: '3', value: 'db_building_use.id_3' }, // i18n-extract-keys ["db_building_use.id_3"]
      { id: '11', value: 'db_building_use.id_11' }, // i18n-extract-keys ["db_building_use.id_11"]
      { id: '12', value: 'db_building_use.id_12' }, // i18n-extract-keys ["db_building_use.id_12"]
      { id: '13', value: 'db_building_use.id_13' }, // i18n-extract-keys ["db_building_use.id_13"]
    ],
    topFilter: false,
    show: {
      type: 'and',
      fields: {
        [FormFields.CONTRACT]: [CONTRACT.VENDITA, CONTRACT.AFFITTO],
        [FormFields.CATEGORY]: [CATEGORIES.PALAZZI_EDIFICI],
      },
    },
  },
  {
    field: FormFields.BOX,
    icon: { name: 'garage', spriteUrl: secondaryFeaturesSprite },
    type: 'multiselect',
    label: 'garage_and_parking_space', // i18n-extract-keys ["garage_and_parking_space"]
    options: [
      { id: '1', value: 'single_garage' }, // i18n-extract-keys ["single_garage"]
      { id: '3', value: 'double_garage' }, // i18n-extract-keys ["double_garage"]
      { id: '4', value: 'parking_space' }, // i18n-extract-keys ["parking_space"]
    ],
    topFilter: false,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.COMMERCIALE,
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.STANZE,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [
          TYPOLOGIES.TERRENO,
          TYPOLOGIES.BOX_GARAGE,
          TYPOLOGIES.PALAZZO_STABILE,
        ],
      },
    },
  },
  {
    field: FormFields.HEATING,
    icon: { name: 'fire', spriteUrl: secondaryFeaturesSprite },
    type: 'multiselect',
    label: 'ac_and_heating', // i18n-extract-keys ["ac_and_heating"]
    options: [
      { id: '1', value: 'autonomous_heating' }, // i18n-extract-keys ["autonomous_heating"]
      { id: '2', value: 'centralized_heating' }, // i18n-extract-keys ["centralized_heating"]
      { id: '10', value: 'air_conditioning' }, // i18n-extract-keys ["air_conditioning"]
    ],
    topFilter: false,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.COMMERCIALE,
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.STANZE,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [
          TYPOLOGIES.TERRENO,
          TYPOLOGIES.BOX_GARAGE,
          TYPOLOGIES.PALAZZO_STABILE,
        ],
      },
    },
  },
  {
    field: FormFields.BALCONY_OR_TERRACE,
    type: 'multiselect',
    label: 'terrace_and_balcony', // i18n-extract-keys ["terrace_and_balcony"]
    options: [
      { id: 'terrazzo', value: 'lbl_terrace' }, // i18n-extract-keys ["lbl_terrace"]
      { id: 'balcone', value: 'lbl_balcony' }, // i18n-extract-keys ["lbl_balcony"]
    ],
    topFilter: false,
    grouped: true,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.COMMERCIALE,
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [
          TYPOLOGIES.TERRENO,
          TYPOLOGIES.BOX_GARAGE,
          TYPOLOGIES.PALAZZO_STABILE,
        ],
      },
    },
  },
  {
    field: FormFields.STATUS,
    icon: { name: 'helmet' },
    type: 'select',
    label: 'immobile_condition', // i18n-extract-keys ["immobile_condition"]
    options: [
      { id: '1', value: 'new_or_under_construction' }, // i18n-extract-keys ["new_or_under_construction"]
      { id: '6', value: 'best_or_renovated' }, // i18n-extract-keys ["best_or_renovated"]
      { id: '2', value: 'good_or_livable' }, // i18n-extract-keys ["good_or_livable"]
      { id: '5', value: 'needs_renovation' }, // i18n-extract-keys ["needs_renovation"]
    ],
    topFilter: false,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.COMMERCIALE,
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.STANZE,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [TYPOLOGIES.TERRENO],
      },
    },
  },
  {
    field: FormFields.PROPERTY_TYPE,
    icon: { name: 'certificate', spriteUrl: filterSprite },
    type: 'select',
    label: 'property_type', // i18n-extract-keys ["property_type"]
    options: [
      { id: '1', value: 'entire_property' }, // i18n-extract-keys ["entire_property"]
      { id: '5', value: 'bare_property' }, // i18n-extract-keys ["bare_property"]
      { id: '9', value: 'partial_property' }, // i18n-extract-keys ["partial_property"]
      { id: '13', value: 'lbl_usufruct' }, // i18n-extract-keys ["lbl_usufruct"]
      { id: '17', value: 'fractional_ownership' }, // i18n-extract-keys ["fractional_ownership"]
      { id: '21', value: 'land_lease' }, // i18n-extract-keys ["land_lease"]
      { id: 'aReddito', value: 'lbl_investment' }, // i18n-extract-keys ["lbl_investment"]
    ],
    topFilter: false,
    hide: {
      fields: {
        [FormFields.CONTRACT]: [CONTRACT.AFFITTO],
        [FormFields.CATEGORY]: [
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.STANZE,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [
          TYPOLOGIES.TERRENO,
          TYPOLOGIES.BOX_GARAGE,
          TYPOLOGIES.PALAZZO_STABILE,
        ],
      },
    },
  },
  {
    field: FormFields.ENERGY_RATING,
    type: 'energy',
    label: 'energy_rating', // i18n-extract-keys ["energy_rating"]
    topFilter: false,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.STANZE,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [TYPOLOGIES.TERRENO, TYPOLOGIES.BOX_GARAGE],
      },
    },
  },
  {
    field: FormFields.ELEVATOR,
    type: 'checkbox',
    label: 'lbl_elevator', // i18n-extract-keys ["lbl_elevator"]
    value: '1',
    topFilter: false,
    grouped: true,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.STANZE,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [TYPOLOGIES.TERRENO],
      },
    },
  },
  {
    field: FormFields.FURNISHED,
    type: 'checkbox',
    label: 'lbl_furnished', // i18n-extract-keys ["lbl_furnished"]
    value: 'on',
    topFilter: false,
    grouped: true,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.COMMERCIALE,
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.STANZE,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [
          TYPOLOGIES.BOX_GARAGE,
          TYPOLOGIES.TERRENO,
          TYPOLOGIES.PALAZZO_STABILE,
        ],
      },
    },
  },
  {
    field: FormFields.BASEMENT,
    type: 'checkbox',
    label: 'lbl_basement', // i18n-extract-keys ["lbl_basement"]
    value: '1',
    topFilter: false,
    grouped: true,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.STANZE,
          CATEGORIES.TURISTICO,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [
          TYPOLOGIES.BOX_GARAGE,
          TYPOLOGIES.TERRENO,
          TYPOLOGIES.PALAZZO_STABILE,
        ],
        [FormFields.HOLIDAYS]: [true],
      },
    },
  },
  {
    field: FormFields.SWIMMING_POOL,
    type: 'checkbox',
    label: 'lbl_pool', // i18n-extract-keys ["lbl_pool"]
    value: '1',
    topFilter: false,
    grouped: true,
    hide: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.COMMERCIALE,
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.STANZE,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.UFFICI_COWORKING,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
        [FormFields.TYPOLOGY]: [
          TYPOLOGIES.BOX_GARAGE,
          TYPOLOGIES.TERRENO,
          TYPOLOGIES.PALAZZO_STABILE,
        ],
      },
    },
  },
  ...(isFeatureEnabled('AUCTIONS_ENABLED')
    ? [
        {
          field: FormFields.EXCLUDE_AUCTIONS,
          icon: { name: 'hammer' },
          type: 'toggle' as const,
          label: 'hide_auctions', // i18n-extract-keys ["hide_auctions"]
          value: '1',
          topFilter: false,
          hide: {
            fields: {
              [FormFields.CONTRACT]: [CONTRACT.AFFITTO, CONTRACT.ASTE],
              [FormFields.CATEGORY]: [
                CATEGORIES.NUOVE_COSTRUZIONI,
                CATEGORIES.STANZE,
                CATEGORIES.TURISTICO,
                CATEGORIES.RICERCA_INTERNAZIONALE,
              ],
              [FormFields.HOLIDAYS]: [true],
            },
          },
        },
      ]
    : []),
  {
    field: FormFields.ALARM_SYSTEM,
    type: 'checkbox',
    label: 'lbl_alarm_system_slash_supervision', // i18n-extract-keys ["lbl_alarm_system_slash_supervision"]
    value: '1',
    topFilter: false,
    grouped: true,
    show: {
      type: 'and',
      fields: {
        [FormFields.CONTRACT]: [CONTRACT.VENDITA, CONTRACT.AFFITTO],
        [FormFields.CATEGORY]: [CATEGORIES.GARAGE_POSTI_AUTO],
      },
    },
  },
  {
    field: FormFields.ANIMALS,
    type: 'checkbox',
    label: 'animals_allowed', // i18n-extract-keys ["animals_allowed"]
    value: '1',
    topFilter: false,
    grouped: true,
    show: {
      fields: {
        [FormFields.CATEGORY]: [CATEGORIES.STANZE],
      },
    },
  },
  {
    field: FormFields.SMOKER,
    type: 'checkbox',
    label: 'smokers_allowed', // i18n-extract-keys ["smokers_allowed"]
    value: '1',
    topFilter: false,
    grouped: true,
    show: {
      fields: {
        [FormFields.CATEGORY]: [CATEGORIES.STANZE],
      },
    },
  },
  {
    field: FormFields.VIRTUAL_TOUR,
    icon: { name: 'tour' },
    type: 'toggle',
    label: 'only_with_virtual_tour', // i18n-extract-keys ["only_with_virtual_tour"]
    value: '1',
    topFilter: false,
    show: {
      fields: {
        [FormFields.CATEGORY]: [
          CATEGORIES.RESIDENZIALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.GARAGE_POSTI_AUTO,
        ],
      },
    },
  },
  {
    field: FormFields.INVESTMENT,
    type: 'checkbox',
    label: 'lbl_investment', // i18n-extract-keys ["lbl_investment"]
    value: '1',
    topFilter: false,
    grouped: true,
    hide: {
      fields: {
        [FormFields.CONTRACT]: [CONTRACT.AFFITTO],
        [FormFields.CATEGORY]: [
          CATEGORIES.RESIDENZIALE,
          CATEGORIES.COMMERCIALE,
          CATEGORIES.NUOVE_COSTRUZIONI,
          CATEGORIES.STANZE,
          CATEGORIES.RICERCA_INTERNAZIONALE,
          CATEGORIES.PALAZZI_EDIFICI,
          CATEGORIES.MAGAZZINI_DEPOSITI,
          CATEGORIES.GARAGE_POSTI_AUTO,
          CATEGORIES.TERRENI,
          CATEGORIES.CAPANNONI,
          CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
        ],
      },
    },
  },
];

if (!isFeatureEnabled('VIRTUAL_TOUR_FILTER')) {
  const index = formConfig.findIndex(
    (config) => config.field === FormFields.VIRTUAL_TOUR
  );

  formConfig.splice(index, 1);
}

if (isFeatureEnabled('SEARCH_MODAL_DISABLE_FILTER_BY_PROPERTY_TYPE')) {
  const index = formConfig.findIndex(
    (config) => config.field === FormFields.PROPERTY_TYPE
  );

  formConfig.splice(index, 1);
}

export const initFormConfig = (
  licenseData?: { id: string; value: string }[],
  language?: Language,
  isAlternate?: boolean,
  geographyInfo?: GeographySearch | null
): FormConfig => {
  const result = [...formConfig];

  if (
    licenseData &&
    licenseData.length &&
    isFeatureEnabled('COMMERCIAL_ACTIVITY_TYPE_FILTER')
  ) {
    const categoryConfigIndex = formConfig.findIndex(
      (config) => config.type === 'category'
    );

    result.splice(categoryConfigIndex + 1, 0, {
      field: FormFields.LICENSE,
      type: 'multiselect',
      label: 'lbl_license', // i18n-extract-keys ["lbl_license"]
      options: licenseData,
      topFilter: false,
      show: {
        type: 'and',
        fields: {
          [FormFields.CATEGORY]: [CATEGORIES.NEGOZI_LOCALI_COMMERCIALI],
          [FormFields.TYPOLOGY]: [
            SHOPS.ATTIVITA,
            SHOPS.MURATT,
            SHOPS.MURATTLAB,
            SHOPS.ATTLAB,
          ],
        },
      },
    });
  }

  const isPlaceSearch = geographyInfo?.searchType === 'place';

  if (isPlaceSearch) {
    const placeId = geographyInfo.value[0].id;

    // identifies the municipality ID for macrozones and microzones via type
    const parentsPlaceId =
      geographyInfo.value[0]?.parents?.find((parent) => parent.type === 2)
        ?.id || 0;

    const isAdminCentreSearch = adminCentres.includes(
      Number(parentsPlaceId || placeId)
    );

    // if locality is not an admin centers,
    // replaces floor filter with garden filter
    if (
      !isAdminCentreSearch &&
      isFeatureEnabled('GARDEN_FILTER_ON_FILTER_BAR')
    ) {
      const gardenField = result.findIndex(
        (field) => field.field === FormFields.GARDEN
      );

      const floorField = result.findIndex(
        (field) => field.field === FormFields.FLOOR_ZONE
      );

      result.splice(gardenField, 1, {
        ...result[gardenField],
        topFilter: true,
      });

      result.splice(floorField, 1, {
        ...result[floorField],
        topFilter: false,
      });
    }
  }

  if (isFeatureEnabled('BEDROOM_SEARCH_ENABLED')) {
    const roomField = result.findIndex(
      (field) => field.field === FormFields.NUMBER_OF_ROOMS
    );

    result.splice(roomField, 1, {
      field: FormFields.BEDROOMS,
      icon: { name: 'planimetry', spriteUrl: mainFeaturesSprite },
      type: 'range',
      label: 'lbl_filter_bedrooms', // i18n-extract-keys ["lbl_filter_bedrooms"]
      fromName: FormFields.MIN_BEDROOMS,
      toName: FormFields.MAX_BEDROOMS,
      translationKeys: {
        label: 'lbl_filter_bedrooms', // i18n-extract-keys ["lbl_filter_bedrooms"]
        fromValue: {
          label: 'range_minumum_bedroom', // i18n-extract-keys ["range_minumum_bedroom"]
          withCount: true,
        },
        toValue: {
          label: 'range_maximum_bedroom', // i18n-extract-keys ["range_maximum_bedroom"]
          withCount: true,
        },
        sameValue: {
          label: 'nr_bedroom', // i18n-extract-keys ["nr_bedroom"]
          withCount: true,
        },
        fromToValue: 'bedroom_range_min_max_with_text', // i18n-extract-keys ["bedroom_range_min_max_with_text"]
        value: 'room_range_value', // i18n-extract-keys ["room_range_value"]
        from: 'room_range_label_from', // i18n-extract-keys ["room_range_label_from"]
        to: 'room_range_label_to', // i18n-extract-keys ["room_range_label_to"]
        title: 'lbl_filter_bedrooms', // i18n-extract-keys ["lbl_filter_bedrooms"]
      },
      topFilter: true,
      keepZeros: true,
      conditionType: 'or',
      hide: {
        fields: {
          [FormFields.CATEGORY]: [
            CATEGORIES.COMMERCIALE,
            CATEGORIES.STANZE,
            CATEGORIES.PALAZZI_EDIFICI,
            CATEGORIES.MAGAZZINI_DEPOSITI,
            CATEGORIES.GARAGE_POSTI_AUTO,
            CATEGORIES.UFFICI_COWORKING,
            CATEGORIES.TERRENI,
            CATEGORIES.CAPANNONI,
            CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
          ],
          [FormFields.TYPOLOGY]: [
            TYPOLOGIES.BOX_GARAGE,
            TYPOLOGIES.BOX_AUTO,
            TYPOLOGIES.TERRENO,
            TYPOLOGIES.PALAZZO_STABILE,
            TYPOLOGIES.MAGAZZINO,
          ],
        },
      },
      range: [
        {
          min: 0,
          max: 5,
          step: {
            0: 1,
          },
        },
      ],
    });
  }

  if (language) {
    result.push({
      field: FormFields.LANGUAGE,
      type: 'hidden',
      label: '',
      value: language,
      topFilter: false,
    });
  }

  // TODO Remove when we will have translations for alternate site
  if (isAlternate) {
    const contractField = result.findIndex(
      (field) => field.type === 'contract'
    );

    //@ts-ignore
    result[contractField].options[CONTRACT.VENDITA] = 'lbl_for_sale';
    //@ts-ignore
    result[contractField].options[CONTRACT.AFFITTO] = 'lbl_for_rent';
    if (isFeatureEnabled('AUCTIONS_ENABLED')) {
      //@ts-ignore
      result[contractField].options[CONTRACT.ASTE] = 'lbl_auction';
    }
  }

  return result;
};
