import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { geographySearchAtom } from './atoms';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { useComponentControls } from './hooks/useComponentControls';

import {
  isPlaceSearchGeography,
  isTouristicAreaGeography,
} from 'src/libs/geography';

import type { GeographySearchFeatureToggles } from './types';

import { AlphaAlertButton } from './AlphaAlertButton';
import { GeographySearchProvider } from './context';
import { DesktopGeographySearch } from './DesktopGeographySearch';
import { MobileGeographySearch } from './MobileGeographySearch';
import { SearchModal } from './SearchModal';

import css from './styles.module.scss';

const isAlphaAlertEnabled = isFeatureEnabled(
  'REACT_GEOGRAPHY_SEARCH_ALPHA_ALERT'
);

interface ReactGeographySearchProps {
  searchFeatures: GeographySearchFeatureToggles;
  error?: boolean;
  hasResultCountButton?: boolean;
  hasGenericTag?: boolean;
  customClass?: string;
  geographyTagCustomClass?: string;
}

export function ReactGeographySearch({
  searchFeatures,
  error = false,
  hasGenericTag = false,
  hasResultCountButton,
  customClass,
  geographyTagCustomClass,
}: ReactGeographySearchProps) {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);
  const isItalyGeography =
    geographyData &&
    isPlaceSearchGeography(geographyData) &&
    geographyData.value.length === 1 &&
    geographyData.value[0].id === 'IT';

  // Italian country level geographies (e.g: `vendita-case/italia/`) and
  // touristic area geographies (e.g: `vendita-case/italia/cinque-terre/`)
  // are not editable because the GeographySearch component can't handle them.
  const isCurrentSearchEditable =
    Boolean(geographyData) &&
    !isItalyGeography &&
    !isTouristicAreaGeography(geographyData);

  const geographySearchProviderProps = {
    searchFeatures,
    hasGenericTag,
    isCurrentSearchEditable,
  };

  return (
    <GeographySearchProvider {...geographySearchProviderProps}>
      <div className={clsx(css['in-geographySearch'], customClass)}>
        {maxScreenWidthSm ? (
          <MobileGeographySearch
            error={error}
            providerProps={geographySearchProviderProps}
          />
        ) : (
          <DesktopGeographySearch
            error={error}
            hasResultCountButton={hasResultCountButton}
            geographyTagCustomClass={geographyTagCustomClass}
          />
        )}

        {isAlphaAlertEnabled && <AlphaAlertButton />}
      </div>

      <SearchModal providerProps={geographySearchProviderProps} />
    </GeographySearchProvider>
  );
}

// Useful for controlling this component from the outside
export const useGeographySearchControls = useComponentControls;

export type { GeographySearchFeatureToggles };
