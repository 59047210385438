import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { AuthModal } from '@indomita-react/auth-modal';
import { useAuthContext } from '@indomita-react/auth-provider';
import { emit } from '@pepita/dom';
import { useTranslations } from '@pepita-react/i18n';
import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import type {
  AuthAction,
  AuthServiceProvider,
} from 'src/components/AuthModal/atoms/authModalAtom';
import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';

import { isFeatureEnabled } from 'src/config/features-toggle';
import type { ProductConfig } from 'src/config/product';
import { getProductConfig } from 'src/config/product';

import type { Language } from 'src/types/translations';

import { getAgencyUrl } from 'src/utils/getAgencySignupUrl';

interface AuthButtonProps {
  label: 'login' | 'register';
  customClass?: string;
}

export const AuthButton = memo(function AuthButton({
  label,
  customClass,
}: AuthButtonProps) {
  const { trans } = useTranslations();
  const { locale, defaultLocale } = useRouter() as {
    defaultLocale: Language;
    locale: Language;
  };
  const loginButtonRef = useRef<HTMLElement>();

  function getLabel(label: 'login' | 'register') {
    if (label === 'register') return trans('act_register');

    return trans('act_login');
  }

  //!isFeatureEnabled('ENABLE_UNIQUE_AUTH_MODAL')
  const { setIsAuthenticated } = useAuthContext();
  const [openModal, setOpenModal] = useState(false);
  const domainName: ProductConfig['domainName'] =
    getProductConfig('domainName');
  let token: string | undefined;

  useEffect(() => {
    token = document.querySelector<HTMLMetaElement>("meta[name='csrf-token']")
      ?.content;
  }, []);
  //end !isFeatureEnabled('ENABLE_UNIQUE_AUTH_MODAL')

  const setAuthModal = useSetAtom(authModalAtom);

  const handleOnClick = () =>
    setAuthModal({
      open: true,
      onClose: () => {
        setAuthModal({ open: false });
      },
      onSuccess: (action: AuthAction, service?: AuthServiceProvider) => {
        if (
          ['apple', 'google', 'facebook'].some(
            (social) => service?.includes(social)
          ) ||
          action === 'login' ||
          action === 'signup'
        ) {
          setAuthModal({ open: false });
        }
      },
      showAdvantageInfo: true,
      contentType: 'default',
      agencySignUp: getAgencyUrl(locale, defaultLocale),
    });

  const handleSuccess = useCallback(
    (action: AuthAction, service?: AuthServiceProvider) => {
      setIsAuthenticated(true);
      if (
        ['apple', 'google', 'facebook'].some(
          (social) => service?.includes(social)
        ) ||
        action === 'login' ||
        action === 'signup'
      ) {
        if (loginButtonRef.current) {
          emit(loginButtonRef.current, 'user:auth');
        }
      }
    },
    [setIsAuthenticated]
  );

  return (
    <>
      <im-login-button
        ref={loginButtonRef}
        role="button"
        aria-label={getLabel(label)}
        onClick={
          isFeatureEnabled('ENABLE_UNIQUE_AUTH_MODAL')
            ? handleOnClick
            : () => setOpenModal(true)
        }
        class={customClass}
      >
        {getLabel(label)}
      </im-login-button>
      {openModal && (
        <AuthModal
          token={token}
          contentType="default"
          advantageInfo
          onClose={() => {
            if (loginButtonRef.current) {
              emit(loginButtonRef.current, 'user:auth-cancel');
            }

            setOpenModal(false);
          }}
          onSuccess={(action, response) => {
            handleSuccess(action, response?.service);
          }}
          agencySignUp={getAgencyUrl(locale, defaultLocale)}
          domainName={domainName}
          locale={getProductConfig('countryCode')}
          phoneCountryCode={getProductConfig('phoneCountryCode')}
        />
      )}
    </>
  );
});
