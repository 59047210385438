import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';

import { useGeographySearchAtom } from './useGeographySearchAtom';

import { isMetro } from '../utils/entity';
import { getMobileSearchPlaceholder } from '../utils/placeholders';

import { useIsSearchFeaturesEnabled } from '../context';

export const useGetPlaceAutocompletePlaceholder = (
  isPlaceCheckListOpen: boolean
) => {
  const { trans } = useTranslations();
  const [geographySearch] = useGeographySearchAtom();
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const isSearchFeaturesEnabled = useIsSearchFeaturesEnabled();
  const isMetroSearchEnabled = isSearchFeaturesEnabled('metroSearch');

  const isCheckListEnabled = !maxScreenWidthSm && isPlaceCheckListOpen;

  const isMetroListEnabled =
    isCheckListEnabled &&
    Boolean(geographySearch) &&
    isMetro(geographySearch.value[0]);

  const isPlaceSearch = geographySearch?.searchType === 'place';

  const isPlaceMetroSearch =
    geographySearch?.searchType === 'place'
      ? isMetro(geographySearch.value[0])
      : false;

  const getPlaceAutocompletePlaceholder = (
    isInputFocused: boolean,
    hasTag = true
  ) => {
    if (
      Boolean(geographySearch) &&
      !isInputFocused &&
      !isCheckListEnabled &&
      !isMetroListEnabled
    ) {
      return '';
    }

    if (maxScreenWidthSm) {
      return getMobileSearchPlaceholder(trans, isMetroSearchEnabled);
    }

    if (isPlaceSearch && hasTag) {
      if (isPlaceMetroSearch) {
        return trans('geography_search_metro_placeholder');
      }

      return trans('geography_search_locality_placeholder');
    }

    return trans('geography_search_new_search_placeholder');
  };

  return getPlaceAutocompletePlaceholder;
};
