import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext } from 'react';

import type { GeographySearchFeatureToggles } from './types';

const GeographySearchContext = createContext<
  GeographySearchProviderProps | undefined
>(undefined);

export interface GeographySearchProviderProps extends PropsWithChildren {
  searchFeatures: GeographySearchFeatureToggles;
  hasGenericTag: boolean;
  isCurrentSearchEditable: boolean;
}

/** Simple provider to avoid terrible props drilling for feature toggles **/
export function GeographySearchProvider(props: GeographySearchProviderProps) {
  const { children, ...rest } = props;

  return (
    <GeographySearchContext.Provider value={rest}>
      {children}
    </GeographySearchContext.Provider>
  );
}

export const useGeographySearchContext = () => {
  const context = useContext(GeographySearchContext);

  if (!context) {
    throw new Error(
      'useGeographySearchContext must be used within a GeographySearch component'
    );
  }

  return context;
};

export const useIsSearchFeaturesEnabled = () => {
  const context = useContext(GeographySearchContext);

  if (!context) {
    throw new Error(
      'useGeographySearchContext must be used within a GeographySearch component'
    );
  }

  return useCallback(
    (feature: keyof GeographySearchFeatureToggles) =>
      Boolean(context.searchFeatures[feature]),
    [context]
  );
};
