import { useCallback, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { geographyComponentStateAtom, geographySearchAtom } from '../atoms';

import { useComponentControls } from '../hooks/useComponentControls';
import { useGetPlaceAutocompletePlaceholder } from '../hooks/useGetPlaceAutocompletePlaceholder';

import {
  isDistanceSearchGeography,
  isPlaceSearchGeography,
} from 'src/libs/geography';

import type { GeographySearchProviderProps } from '../context';
import {
  GeographySearchProvider,
  useGeographySearchContext,
  useIsSearchFeaturesEnabled,
} from '../context';
import { GeographyTag } from '../GeographyTag';
import { PlaceAutocomplete } from '../PlaceAutocomplete';
import { SearchOptions } from '../SearchOptions';
import { SearchOptionModal } from '../SearchOptionsModal';

import css from './styles.module.scss';

interface MobileGeographySearchProps {
  providerProps: GeographySearchProviderProps;
  error: boolean;
}

export function MobileGeographySearch({
  providerProps,
  error = false,
}: MobileGeographySearchProps) {
  const autocompleteInputRef = useRef<HTMLInputElement>(null);
  const {
    closeSearchOptionsModal,
    openSearchOptionsModal,
    openSearchModal,
    closeSearchOptions,
  } = useComponentControls();
  const { searchOptionsModalOpen } = useAtomValue(geographyComponentStateAtom);
  const geographySearch = useAtomicStateAtomValue(geographySearchAtom);
  const { hasGenericTag } = useGeographySearchContext();
  const isTagVisible = Boolean(geographySearch) || hasGenericTag;
  const [hasTag, setHasTag] = useState(isTagVisible);

  const getPlaceAutocompletePlaceholder =
    useGetPlaceAutocompletePlaceholder(false);

  const isSearchFeaturesEnabled = useIsSearchFeaturesEnabled();
  const isAreaSearchEnabled = isSearchFeaturesEnabled('areaSearch');
  const isDistanceSearchEnabled = isSearchFeaturesEnabled('distanceSearch');

  const handleGeographyTagClick = useCallback(() => {
    if (!geographySearch) return;

    closeSearchOptions();

    if (isPlaceSearchGeography(geographySearch)) {
      openSearchModal('PLACE', hasTag);

      return;
    }

    if (isDistanceSearchGeography(geographySearch)) {
      openSearchModal('DISTANCE', hasTag);

      return;
    }

    openSearchModal('AREA', hasTag);
  }, [geographySearch, closeSearchOptions, openSearchModal, hasTag]);

  const handleSearchByNameSelect = () => {
    if (hasTag && geographySearch) {
      if (isPlaceSearchGeography(geographySearch)) {
        openSearchModal('PLACE', true);

        return;
      }

      openSearchModal('PLACE', false);

      return;
    }

    autocompleteInputRef.current?.focus();
  };

  const handleGeographyTagRemove = useCallback(() => {
    setHasTag(false);

    if (!searchOptionsModalOpen) {
      openSearchOptionsModal();
    }
  }, [openSearchOptionsModal, searchOptionsModalOpen]);

  const handleCloseSearchOptionsModal = () => {
    closeSearchOptionsModal();
    setHasTag(isTagVisible);
  };

  return (
    <>
      <PlaceAutocomplete error={error}>
        <PlaceAutocomplete.InputContainer>
          {hasTag ? (
            <GeographyTag
              onClick={handleGeographyTagClick}
              onRemove={handleGeographyTagRemove}
            />
          ) : (
            <PlaceAutocomplete.Icon />
          )}

          <PlaceAutocomplete.Input
            placeholder={getPlaceAutocompletePlaceholder}
            onClick={openSearchOptionsModal}
          />
        </PlaceAutocomplete.InputContainer>
      </PlaceAutocomplete>

      {searchOptionsModalOpen && (
        <SearchOptionModal onClose={handleCloseSearchOptionsModal}>
          <GeographySearchProvider {...providerProps}>
            <PlaceAutocomplete
              customClass={css['in-mobileGeographySearch__autocomplete']}
              inputRef={autocompleteInputRef}
              hasFixedHeight
              //todo: in realtà deve aprire il placeSearch, da fare in altra MR
              onSetGeography={() => setHasTag(true)}
            >
              <PlaceAutocomplete.InputContainer
                customClass={css['in-mobileGeographySearch__inputContainer']}
              >
                {hasTag ? (
                  <GeographyTag
                    onClick={handleGeographyTagClick}
                    onRemove={handleGeographyTagRemove}
                    hasFixedHeight
                    customClass={css['in-mobileGeographySearch__tag']}
                  />
                ) : (
                  <>
                    <PlaceAutocomplete.Icon hasLeftSpace showBackIcon />

                    <PlaceAutocomplete.Input
                      placeholder={(isFocused) =>
                        getPlaceAutocompletePlaceholder(isFocused, false)
                      }
                    />
                  </>
                )}
              </PlaceAutocomplete.InputContainer>

              <PlaceAutocomplete.SuggestionList />
            </PlaceAutocomplete>

            <SearchOptions>
              <SearchOptions.CurrentSearch isVisible={hasTag} />
              <SearchOptions.SearchByName onSelect={handleSearchByNameSelect} />
              {isAreaSearchEnabled && (
                <SearchOptions.DrawAreaOnMap hasInitialValue={hasTag} />
              )}
              {isDistanceSearchEnabled && (
                <SearchOptions.DistanceFromAPoint hasInitialValue={hasTag} />
              )}
            </SearchOptions>
          </GeographySearchProvider>
        </SearchOptionModal>
      )}
    </>
  );
}
