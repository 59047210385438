import type {
  FormConfig,
  FormFields,
  VisibilityConstraint,
} from '../config/formConfigTypes';

import type { FormState } from '../types';

import { nonNullable } from 'src/utils/common';

/** Utility to understand when a field is hidden or not
 *
 *  - By default (when no filter is declared) a field is visible
 *  - If <b>show</b> filter is declared, when the condition is <b>true</b>
 *  the field is visible
 *  - If <b>hide</b> filter is declared, when the condition is <b>true</b>
 *  the field is hidden, otherwise is visible
 *
 * @param filter
 * @param state
 */
const isHidden = (
  filter: { show: VisibilityConstraint; hide: VisibilityConstraint },
  state
) => {
  let hidden = false;

  if (filter.show) {
    if (matches(state, filter.show.fields, filter.show.type || 'or')) {
      return false;
    }

    hidden = true;
  }

  if (filter.hide) {
    return matches(state, filter.hide.fields, filter.hide.type || 'or');
  }

  return hidden;
};

const matches = (
  state: FormState,
  filter: VisibilityConstraint['fields'],
  conditionType: 'and' | 'or'
) => {
  const conditionsFields = Object.keys(filter) as FormFields[];

  const predicate = (conditionKey) =>
    evaluateCondition(filter[conditionKey], state[conditionKey]);

  return conditionType === 'and'
    ? conditionsFields.every(predicate)
    : conditionsFields.some(predicate);
};

const evaluateCondition = (condition, stateValue) =>
  Array.isArray(stateValue)
    ? condition.find((value) => stateValue.find((v) => v === value))
    : condition.find((value) => value === stateValue);

export const getHiddenFieldsFromState = (
  formState: FormState,
  formConfig: FormConfig
) => {
  const hiddenFields = formConfig
    .map((fieldConfig) =>
      // Check if field is hidden given the current state
      isHidden({ show: fieldConfig.show, hide: fieldConfig.hide }, formState)
        ? fieldConfig
        : null
    )
    .filter((config) => Boolean(config));

  // Special behaviour for group field (other features...)
  const groupIsVisible = formConfig
    .filter((config) => config.grouped)
    .some(
      (config) =>
        !hiddenFields.find((hiddenField) => hiddenField.field === config.field)
    );

  if (!groupIsVisible) {
    hiddenFields.push(formConfig.find((config) => config.type === 'group'));
  }

  return hiddenFields.filter(nonNullable);
};
