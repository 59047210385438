import { atom } from 'jotai';

import { createAtomicStateStateAtom } from 'src/atoms/atomic-state';

// TODO In the future we will need to refactor these atoms in order
import { formStateAtom } from 'src/components/SearchFilters/atoms';

import { CATEGORIES, CONTRACT } from 'src/constants/typologies';

import type { GeographySearch } from 'src/libs/geography';

interface ComponentState {
  searchOptionsOpen: boolean;
  searchOptionsModalOpen: boolean;
  searchModalOpen: boolean;
}

export const geographyComponentStateAtom = atom<ComponentState>({
  searchOptionsOpen: false,
  searchOptionsModalOpen: false,
  searchModalOpen: false,
});

export const geographySearchAtom =
  createAtomicStateStateAtom<GeographySearch | null>({
    key: 'GeographySearch',
    default: null,
    deepEqualityCheck: true,
    setup: (self, { watch, set, get }) => {
      watch(() => {
        const formState = get(formStateAtom);

        // When the user does a search changing the category or the contract
        // we may need to delete the address search parameters as they are
        // supported only for sales and rents and for residential properties
        if (
          formState?.idCategoria !== CATEGORIES.RESIDENZIALE ||
          formState?.idContratto === CONTRACT.ASTE
        ) {
          set(self, (state) => {
            if (
              state?.searchType === 'polygon' &&
              state?.value?.['indirizzo']
            ) {
              return {
                ...state,
                value: {
                  ...state.value,
                  indirizzo: undefined,
                  idIndirizzoPoligoni: undefined,
                  idComune: undefined,
                  idProvincia: undefined,
                },
              };
            }

            return state;
          });
        }
      }, [formStateAtom]);
    },
  });

export const tempGeographySearchAtom = atom<GeographySearch | null>(
  null as unknown as GeographySearch
);
