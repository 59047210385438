import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { useGeographySearchAtom } from './useGeographySearchAtom';

import { isCity, isPlaceSearchGeography } from 'src/libs/geography';

import type { BaseEntityWithChildren, EntityParent } from 'src/types/geography';
import { ENTITY_TYPE } from 'src/types/geography';

import { http } from 'src/utils/client/http';
import {
  getEntityParentByType,
  isMetro,
  isMicrozone,
  isZone,
} from '../utils/entity';
import { enrichChildren } from '../utils/geography';

/**
 * This hook takes the current selected geography and returns the current city
 * with the list of its children. As the geography comes from different apis, we need
 * to check if the current city had been already filled with children (which
 * usually happens with the autocomplete) or if we need another call to the
 * geography-lists api to get them
 */
export const useGetEntityWithChildren = () => {
  const [geographySearch] = useGeographySearchAtom();
  const { locale } = useRouter();
  const [currentCity, setCurrentCity] = useState<
    EntityParent | BaseEntityWithChildren
  >();
  const [currentType, setCurrentType] = useState<
    ENTITY_TYPE.cityZone | ENTITY_TYPE.metroLine
  >(); // Used to choose the list of children we wants inside the city

  useEffect(() => {
    if (!geographySearch) return;

    if (isPlaceSearchGeography(geographySearch)) {
      const currentValue = geographySearch.value[0];

      if (isCity(currentValue)) {
        // The selection is a city
        setCurrentCity(currentValue);
        setCurrentType(ENTITY_TYPE.cityZone);
      } else if (
        isZone(currentValue) ||
        isMicrozone(currentValue) ||
        isMetro(currentValue)
      ) {
        // We get the current city from selection parent
        setCurrentCity(getEntityParentByType(currentValue, ENTITY_TYPE.city));
        setCurrentType(
          isMetro(currentValue)
            ? ENTITY_TYPE.metroLine // get the list of metros
            : ENTITY_TYPE.cityZone // get the list of macrozones
        );
      }
    }
  }, [geographySearch]);

  const { data } = useQuery({
    queryKey: ['geography-lists', currentCity?.id, currentType, locale],
    queryFn: () =>
      (currentCity as BaseEntityWithChildren)?.children?.length
        ? Promise.resolve(currentCity as BaseEntityWithChildren) // The current city already had all we need
        : http
            // Calling an api to get list of children
            .get('/api-next/geography/geography-lists/', {
              searchParams: {
                id: currentCity.id,
                type: currentType,
                __lang: locale,
              },
            })
            .json()
            .then<BaseEntityWithChildren>((response) => ({
              ...response,
              children: (response.macrozones || response.metros || []).map(
                (child) => enrichChildren(response, child)
              ),
            })),
    enabled: Boolean(currentCity) && Boolean(currentType),
  });

  return data;
};
