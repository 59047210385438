import type { LatLngLiteral } from '@pepita-canary/leaflet';
import { atom } from 'jotai';

export type MapBounds = {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
};

export const mapBoundsAtom = atom<MapBounds | undefined>(
  undefined as unknown as MapBounds
);

export const mapZoomAtom = atom<number | undefined>(
  undefined as unknown as number
);

export const mapCenterAtom = atom<LatLngLiteral | undefined>(
  undefined as unknown as LatLngLiteral
);
