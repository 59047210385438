import { useCallback, useMemo } from 'react';
import SearchSpriteUrl from '@pepita-fe/sprite-search-module/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';
import { useSetAtom } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import type { GeographySearch } from 'src/libs/geography';
import {
  isDistanceSearchCircleGeography,
  isDistanceSearchGeography,
  isDistanceSearchIsolineGeography,
  isPlaceSearchGeography,
} from 'src/libs/geography';
import { PepitaButton } from 'src/libs/ui/pepita-button';
import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { geographySearchAtom } from '../../atoms';
import { comeFromSearchOptionsAtom } from '../../atoms/comeFromSearchOptionsAtom';
import {
  useGeographySearchContext,
  useIsSearchFeaturesEnabled,
} from '../../context';
import { useComponentControls } from '../../hooks/useComponentControls';
import { usePolygonNamingApi } from '../../hooks/usePolygonNamingApi';
import { getDrawingTagLabel } from '../../utils/addresses';
import { isMetro } from '../../utils/entity';
import { getGeographyTagLabel } from '../../utils/labels';

import css from './styles.module.scss';

const getSearchMethod = (geographyData: GeographySearch) => {
  if (isPlaceSearchGeography(geographyData)) return 'PLACE';
  if (isDistanceSearchGeography(geographyData)) return 'DISTANCE';

  return 'AREA';
};

interface CurrentSearchProps {
  isVisible: boolean;
}

export function CurrentSearch({ isVisible }: CurrentSearchProps) {
  const { trans } = useTranslations();
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);
  const isSearchFeaturesEnabled = useIsSearchFeaturesEnabled();
  const { openSearchModal } = useComponentControls();
  const setComeFromSearchOptions = useSetAtom(comeFromSearchOptionsAtom);
  /** Effect for setting drawings label **/
  const polygonLabel = usePolygonNamingApi(geographyData);
  const { isCurrentSearchEditable } = useGeographySearchContext();

  const currentSelection: { label: string; icon: PepitaIconProps } | undefined =
    useMemo(() => {
      if (!geographyData) return;

      if (isPlaceSearchGeography(geographyData)) {
        return {
          icon: {
            name: isMetro(geographyData.value[0]) ? 'metro' : 'zone',
          },
          label: getGeographyTagLabel(
            geographyData.value,
            isSearchFeaturesEnabled('labelsForCountry'),
            trans
          ),
        };
      }

      if (isDistanceSearchCircleGeography(geographyData)) {
        return {
          icon: {
            name: 'point',
          },
          label: getDrawingTagLabel(geographyData, trans, polygonLabel),
        };
      }

      if (isDistanceSearchIsolineGeography(geographyData)) {
        return {
          icon: {
            name: 'point',
          },
          label: geographyData.value.label,
        };
      }

      // Area search
      return {
        icon: {
          name: 'draw',
        },
        label: getDrawingTagLabel(geographyData, trans, polygonLabel),
      };
    }, [geographyData, isSearchFeaturesEnabled, polygonLabel, trans]);

  const handleEditClick = useCallback(() => {
    if (!geographyData) return;

    openSearchModal(getSearchMethod(geographyData), isVisible);
    setComeFromSearchOptions(true);
  }, [geographyData, isVisible, openSearchModal, setComeFromSearchOptions]);

  if (!isVisible || !currentSelection) {
    return null;
  }

  return (
    <div className={css['in-currentSearch__container']}>
      <p className={css['in-currentSearch__title']}>
        {trans('geography_search_current_search_label')}
      </p>
      <div
        className={clsx(
          css['in-currentSearch__element'],
          isCurrentSearchEditable && css['is-editable']
        )}
      >
        <PepitaIcon
          name={currentSelection.icon.name}
          spriteUrl={SearchSpriteUrl}
          customClass={css['in-currentSearch__icon']}
        />
        <span className={css['in-currentSearch__text']}>
          {currentSelection.label}
        </span>
        {isCurrentSearchEditable && (
          <PepitaButton
            variant="link"
            small
            onClick={handleEditClick}
            customClass={css['in-currentSearch__button']}
          >
            {trans('act_edit')}
          </PepitaButton>
        )}
      </div>
      <p className={css['in-currentSearch__title']}>
        {trans('geography_search_new_search_label')}
      </p>
    </div>
  );
}
