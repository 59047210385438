import type { Trans } from 'src/utils/i18nFormatters';

export const getMobileSearchPlaceholder = (
  trans: Trans,
  isMetroEnabled: boolean
) => {
  if (isMetroEnabled) {
    return trans('insert_province_city_zones');
  }

  return trans('insert_province_city_zones_without_metro');
};

export const getSearchModalPlaceholder = (
  trans: Trans,
  isMetroEnabled: boolean
) => {
  if (isMetroEnabled) {
    return trans('hp_select_location');
  }

  return trans('lu_hp_select_location');
};
