import { ENTITY_TYPE } from '@indomita-website/map-polygons/src/types';
import type { SearchMode } from '@indomita-website/search/types';
import { http } from '@pepita/http';
import { decode } from '@pepita/polyline';
import type { LatLngLiteral } from '@pepita-canary/leaflet';

import { getProductConfig } from 'src/config/product';

import type { GeographySearch } from 'src/libs/geography';
import {
  isAreaSearchGeography,
  isDistanceSearchCircleGeography,
  isDistanceSearchIsolineGeography,
  isPlaceSearchGeography,
} from 'src/libs/geography';

import type { GeographySearchParams, SearchParams } from 'src/types/search';
import type {
  BaseEntity,
  EntityKey,
  GeographySearchVRT,
  GeographyValueWithPolygon,
} from './types';

import { convertPolygonsPointsToString } from 'src/utils/geography';

const mapConfig = getProductConfig('mapConfig');

export { ENTITY_TYPE };

export function deserializePolygonPath(path: string[]): LatLngLiteral[][] {
  return path.map((polygon) =>
    decode(polygon).map((p) => ({ lat: p[0], lng: p[1] }))
  );
}

const TYPE2NAME: Partial<Record<ENTITY_TYPE, keyof SearchParams>> = {
  [ENTITY_TYPE.city]: 'idComune',
  [ENTITY_TYPE.province]: 'idProvincia',
  [ENTITY_TYPE.cityZone]: 'idMZona',
  [ENTITY_TYPE.macroarea]: 'idMacroarea',
  [ENTITY_TYPE.microzone]: 'idQuartiere',
  [ENTITY_TYPE.metroLine]: 'fkMetro',
  [ENTITY_TYPE.metro]: 'fkMetroStation',
  [ENTITY_TYPE.region]: 'fkRegione',
  [ENTITY_TYPE.country]: 'idNazione',
  [ENTITY_TYPE.touristicArea]: 'idAreaTuristica',
};

const placeValuesToParams = (
  geographyValues: BaseEntity[]
): GeographySearchParams => {
  const values = {};

  geographyValues.forEach((entity) => {
    if (!values[entity.type]) {
      values[entity.type] = new Set<BaseEntity['id']>();
    }

    values[entity.type].add(entity.id);
    if (entity.parents) {
      entity.parents.forEach((parent) => {
        if (!values[parent.type]) {
          values[parent.type] = new Set<BaseEntity['id']>();
        }

        values[parent.type].add(parent.id);
      });
    }
  });

  const mappedPlaces = {};

  for (const entityType in values) {
    const paramName = TYPE2NAME[entityType];

    if (paramName) {
      switch (parseInt(entityType, 10)) {
        case ENTITY_TYPE.province:
        case ENTITY_TYPE.macroarea:
        case ENTITY_TYPE.city:
        case ENTITY_TYPE.region:
        case ENTITY_TYPE.country:
        case ENTITY_TYPE.touristicArea:
          // Cities and provinces should not be arrays
          mappedPlaces[paramName] = [...values[entityType]][0];
          break;
        default:
          mappedPlaces[paramName] = [...values[entityType]];
      }
    }
  }

  return mappedPlaces;
};

export function geographyToSearchParams(geography: GeographySearch | null) {
  const params: SearchParams = {};

  if (!geography) {
    return params;
  }

  if (isPlaceSearchGeography(geography)) {
    const values = placeValuesToParams(geography.value);

    if (geography.idMacroarea) {
      values.idMacroarea = geography.idMacroarea;
    }

    return values;
  }

  if (isDistanceSearchCircleGeography(geography)) {
    params.raggio = String(Math.round(geography.value.radius));
    params.centro = geography.value.center.join(',');
  }

  if (isAreaSearchGeography(geography)) {
    if (geography.value.idComune) {
      params.idComune = geography.value.idComune;
    }

    if (geography.value.idProvincia) {
      params.idProvincia = geography.value.idProvincia;
    }

    if (geography.value.indirizzo) {
      params.indirizzo = geography.value.indirizzo;
    }

    if (geography.value.idIndirizzoPoligoni) {
      params.idIndirizzoPoligoni = geography.value.idIndirizzoPoligoni;
    }
  }

  if (
    isDistanceSearchIsolineGeography(geography) ||
    isAreaSearchGeography(geography)
  ) {
    params.vrt = convertPolygonsPointsToString(geography.value.points);
  }

  return params;
}

export const entityKeyToGeographyValue = async (
  values: EntityKey[]
): Promise<GeographyValueWithPolygon[]> => {
  return Promise.all(values.map(polygonApi)).then((items) => {
    // Some places don't have polygons, other had an empty array of polygons.
    return items
      .filter((item) => item.polygons && item.polygons[0])
      .map((item) => item.polygons[0]);
  });
};

/**
 * Normalize formats for polygons searches
 * @param geography
 */
export function searchDataToGeographyVRT(
  geography: GeographySearch | null
): GeographySearchVRT | null {
  if (!geography) return null;

  if (isDistanceSearchCircleGeography(geography)) {
    return {
      searchType: 'vrt',
      value: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(geography.value.center[1] as string),
            parseFloat(geography.value.center[0] as string),
          ],
        },
        properties: {
          radius: geography.value.radius,
          selectionMethod: geography.value.selectionMethod,
        },
      },
    };
  }

  if (isAreaSearchGeography(geography)) {
    return {
      searchType: 'vrt',
      value: {
        type: 'Feature',
        properties: {
          idComune: geography.value.idComune,
          idProvincia: geography.value.idProvincia,
          indirizzo: geography.value.indirizzo,
          idIndirizzoPoligoni: geography.value.idIndirizzoPoligoni,
          address: geography.value.address,
          label: geography.value.label,
          time: geography.value.time,
          transport: geography.value.transport,
          cityLabel: geography.value.cityLabel,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [geography.value.points.map(([lat, lng]) => [lng, lat])],
        },
      },
    };
  }

  if (isDistanceSearchIsolineGeography(geography)) {
    return {
      searchType: 'vrt',
      value: {
        type: 'Feature',
        properties: {
          address: geography.value.address,
          label: geography.value.label,
          time: geography.value.time,
          transport: geography.value.transport,
          selectionMethod: geography.value.selectionMethod,
          type: geography.value.type,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [geography.value.points.map(([lat, lng]) => [lng, lat])],
        },
      },
    };
  }

  return null;
}

/**
 * Normalize formats for polygons searches
 * @param data
 */
export function geographyVRTToSearchData(
  data: GeographySearchVRT
): GeographySearch {
  if (data.searchType === 'vrt') {
    if (data.value.geometry.type === 'Point') {
      return {
        searchType: 'circle',
        value: {
          ...data.value.properties,
          center: [
            data.value.geometry.coordinates[1],
            data.value.geometry.coordinates[0],
          ],
        },
      };
    } else if (data.value.geometry.type === 'Polygon') {
      if (data?.value?.geometry?.coordinates) {
        return {
          searchType: 'polygon',
          value: {
            ...data.value.properties,
            points: data.value.geometry.coordinates[0].map((p) => {
              return [p[1], p[0]];
            }),
          },
        };
      }

      // Sometimes (especially for isochronous search) coordinates have
      // not been set (this can happen when the user clicks very fast)
      return null;
    }
  }

  return null;
}

export const getGeographyEntityLabel = (
  geography: GeographySearch | null,
  type: ENTITY_TYPE
): string =>
  (geography?.value?.[0]?.type === type
    ? geography.value[0].label
    : geography?.value?.[0]?.parents?.find((parent) => parent.type === type)
        ?.label) || null;

export const getGeographyEntityLabelZone = (
  geography: GeographySearch | null,
  type: ENTITY_TYPE
): string[] => {
  if (geography?.searchType === 'place' && geography.value[0]?.type === type) {
    return geography.value.map((_, i) => geography.value[i].label);
  } else if (geography?.searchType === 'place') {
    return geography.value.map(
      (_, i) =>
        geography.value[i].parents.find((p) => p.type === type)?.label || ''
    );
  }

  return [];
};

export const getGeographyEntityID = (
  geography: GeographySearch | null,
  type: ENTITY_TYPE
): string =>
  (geography?.value?.[0]?.type === type
    ? geography.value[0].id
    : geography?.value?.[0]?.parents?.find((parent) => parent.type === type)
        ?.id) || null;

export const geographySearchToggleMap = (type: SearchMode) => {
  const geographyComponent = document.querySelector(
    'nd-geography-search'
  ) as any; // eslint-disable-line @typescript-eslint/no-explicit-any

  if (!geographyComponent) return;

  geographyComponent.toggleMap(type);
};

const getPolygonApi =
  (host: string, cdnVersion: string) =>
  async (
    key: EntityKey
  ): Promise<{ polygons: GeographyValueWithPolygon[] }> => {
    const lang = document.documentElement.lang.slice(0, 2);

    const url = `${host}/api-next/geography/polygon/${key.type}/${key.id}/${cdnVersion}/`;
    const urlWithLang = lang ? `${url}${lang}/` : url;

    return http.get(urlWithLang).json();
  };

export const polygonApi = getPolygonApi(
  mapConfig.polygonServiceHost,
  mapConfig.polygonsServiceVersion
);
