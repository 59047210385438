import { type Reducer, type Ref, useRef } from 'react';

import { useAutocompleteApi } from './useAutocompleteApi';
import { useAutocompleteNavigation } from './useAutocompleteNavigation';
import { useAutocompleteSearch } from './useAutocompleteSearch';
import { useAutocompleteState } from './useAutocompleteState';

import type { AutocompleteActions, AutocompleteApi } from '../types';

interface UseAutocompleteParams<V> {
  input: Ref<HTMLInputElement>;
  autocompleteApi: AutocompleteApi<V>;
  onChange?: (values: V[]) => void;
  reducer?: Reducer<V[] | undefined, AutocompleteActions<V>>;
  enabled?: boolean;
  defaultValue?: V[];
  values?: V[];
  minLength?: number;
  withBackspace?: boolean;
}

export const useAutocomplete = <V>({
  autocompleteApi,
  onChange,
  input,
  reducer = (_, a) => a.changes,
  defaultValue = [],
  minLength = 2,
  enabled = true,
  withBackspace = true,
  values: controlledValues,
}: UseAutocompleteParams<V>) => {
  const { inFocus, search } = useAutocompleteSearch(input);

  const isOpen = enabled && inFocus && search.length >= minLength;

  const keyboardPositionRef =
    useRef<ReturnType<typeof useAutocompleteNavigation<V>>>();

  const { items, loading } = useAutocompleteApi<V>(
    search,
    autocompleteApi,
    isOpen,
    (state, prev) => {
      if (state.items !== prev.items) {
        keyboardPositionRef.current?.set(0);
      }
    }
  );

  const { values, selectItem, removeItem, reset } = useAutocompleteState(
    defaultValue,
    controlledValues,
    reducer,
    onChange
  );

  const keyboardPosition = useAutocompleteNavigation(
    input,
    items,
    values,
    isOpen,
    withBackspace && search === '' && values.length > 0,
    selectItem,
    removeItem
  );

  // Update the reference to keyboardPositionRef
  keyboardPositionRef.current = keyboardPosition;

  function handleItemHover(index: number) {
    return () => {
      if (
        keyboardPositionRef.current &&
        keyboardPositionRef.current.value !== index
      ) {
        keyboardPositionRef.current.set(index);
      }
    };
  }

  return {
    highlight: keyboardPositionRef.current?.value,
    setHighlight: keyboardPositionRef.current?.set,
    inFocus,
    search,
    values,
    items,
    loading,
    selectItem,
    removeItem,
    reset,
    handleItemHover,
    enabled: isOpen,
  };
};
