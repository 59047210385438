import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';

export enum FormFields {
  CONTRACT = 'idContratto',
  CATEGORY = 'idCategoria',
  PRICE = 'prezzo',
  MIN_PRICE = 'prezzoMinimo',
  MAX_PRICE = 'prezzoMassimo',
  SURFACE = 'superficie',
  MIN_SURFACE = 'superficieMinima',
  MAX_SURFACE = 'superficieMassima',
  TYPOLOGY = 'idTipologia',
  ROOM_TYPOLOGY = 'tipologiaStanza',
  ROOM_BED_TYPOLOGY = 'tipologiaPostoLetto',
  TENANT_SEX = 'sessoInquilini',
  TENANT_JOB = 'occupazioneInquilini',
  NUMBER_OF_ROOMS = 'locali',
  MIN_NUMBER_OF_ROOMS = 'localiMinimo',
  MAX_NUMBER_OF_ROOMS = 'localiMassimo',
  BATHROOMS = 'bagni',
  STATUS = 'stato',
  PROPERTY_TYPE = 'tipoProprieta',
  FLOOR_ZONE = 'fasciaPiano',
  BOX = 'boxAuto',
  BUILDING_USE = 'usoEdificio',
  HEATING = 'riscaldamenti',
  BALCONY_OR_TERRACE = 'balconeOterrazzo',
  GARDEN = 'giardino',
  ENERGY_RATING = 'classeEnergetica',
  SORT_CRITERION = 'criterio',
  SORT_ORDER = 'ordine',
  OTHER_FEATURES = 'otherFeatures',
  ELEVATOR = 'ascensore',
  BASEMENT = 'cantina',
  HOLIDAYS = 'vacanze',
  SWIMMING_POOL = 'piscina',
  FURNISHED = 'arredato',
  EXCLUDE_AUCTIONS = 'noAste',
  ALARM_SYSTEM = 'sistemaAllarmeVigilanza',
  ANIMALS = 'animali',
  SMOKER = 'fumatore',
  VIRTUAL_TOUR = 'virtualTour',
  INVESTMENT = 'aReddito',
  LICENSE = 'fkLicenza',
  BEDROOMS = 'camereDaLetto',
  MIN_BEDROOMS = 'camereDaLettoMinimo',
  MAX_BEDROOMS = 'camereDaLettoMassimo',
  LUXURY = 'lusso',
  LANGUAGE = '__lang',
  EXCLUDE_AGENCIES = 'noAgenzie',
  LOW_COST = 'lowcost',
  SEA_VIEW = 'seaView',
  SEA_DISTANCE = 'seaDistance',
}

export type VisibilityConstraint = {
  type?: 'and' | 'or';
  fields: {
    [field in FormFields]?: (number | string | boolean)[];
  };
};
type BaseFieldConfig = {
  icon?: PepitaIconProps;
  field: FormFields;
  topFilter: boolean;
  label: string;
  grouped?: boolean;
  show?: VisibilityConstraint;
  hide?: VisibilityConstraint;
};

export type ContractConfig = BaseFieldConfig & {
  type: 'contract';
  options: { [value: number]: string };
};

export type CategoryConfig = BaseFieldConfig & {
  type: 'category';
};

export type RangeConfig = BaseFieldConfig & {
  type: 'range';
  fromName: FormFields;
  toName: FormFields;
  translationKeys?: {
    label: string;
    fromValue: {
      label: string;
      withCount?: boolean;
    };
    toValue: {
      label: string;
      withCount?: boolean;
    };
    sameValue?: {
      label: string;
      withCount?: boolean;
    };
    fromToValue: string;
    value: string;
    from: string;
    to: string;
    title: string;
  };
  keepZeros?: boolean;
  shortLabelValues?: boolean;
  conditionType: 'and' | 'or';
  range: {
    condition?: { [field in FormFields]?: (number | string)[] };
    min: number;
    max: number;
    step: {
      [_: number]: number;
    };
  }[];
};

export type MultiselectConfig = BaseFieldConfig & {
  type: 'multiselect';
  options: { id: string; value: string }[];
};

export type SelectConfig = BaseFieldConfig & {
  type: 'select';
  options: { id: string; value: string; countLabel?: string; label?: string }[];
};

export type EnergyConfig = BaseFieldConfig & {
  type: 'energy';
};

export type CheckboxConfig = BaseFieldConfig & {
  type: 'checkbox';
  value: string;
};

export type ToggleConfig = BaseFieldConfig & {
  type: 'toggle';
  value: string;
};

export type GroupConfig = BaseFieldConfig & {
  type: 'group';
};

type HiddenConfigType = BaseFieldConfig & {
  type: 'hidden';
  value: string;
};

export type FieldConfig =
  | ContractConfig
  | CategoryConfig
  | RangeConfig
  | MultiselectConfig
  | SelectConfig
  | EnergyConfig
  | CheckboxConfig
  | ToggleConfig
  | GroupConfig
  | HiddenConfigType;

export type FormConfig = FieldConfig[];
